import React, { useState } from 'react';
import AccountCircleOutlined from '@mui/icons-material/AccountCircleOutlined';
import { Popover } from 'antd'; // You'll need to install antd: npm install antd

const SearchPage = () => {
  const [visible, setVisible] = useState(false);

  const handleVisibleChange = (newVisible) => {
    setVisible(newVisible);
  };

  const content = (
    <div>
      {/* Put your dialog content here */}
      <p>我是WangYuGuo</p>
    </div>
  );

  return (
    <div>
      <Popover
        content={content}
        title="哈哈哈"
        trigger="hover"
        visible={visible}
        onVisibleChange={handleVisibleChange}
      >
        <AccountCircleOutlined style={{ fontSize: '24px', cursor: 'pointer' }} />
      </Popover>
    </div>
  );
};

export default SearchPage;