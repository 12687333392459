import React from 'react';
import { Card, CardContent, CardMedia, Typography, Box, Link, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { GlobalData } from '../global_data';


const VideoCard = ({ title, stored_filename, thumbnailUrl, isTranscode, originalFilename, pageType }) => { // 添加 pageType 属性
  //console.log('title: ', title, 'stored_filename: ', stored_filename, 'thumbnailUrl: ', thumbnailUrl, 'isTranscode: ', isTranscode, 'originalFilename: ', originalFilename, 'pageType: ', pageType);

  const navigate = useNavigate();

  const handleClick = () => {
    //navigate(`/play/${stored_filename}`);
    window.open(`/play/${stored_filename}`, '_blank'); // 在新标签页打开 PlayPage
  };

  // 查看教案按钮点击事件
  const handleEditClick = () => {
    //navigate(`/edit/${stored_filename}`);
    window.open(`/edit/${stored_filename}`, '_blank'); // 在新标签页打开 EditLessonPlanPage
  };

  const renderCardContent = () => {
    // 根据页面类型和转码状态来决定显示内容
    if (pageType === 'mine') {  // "我的视频" 页面
      if (isTranscode === 0) {
        return (
          <>
            <CardMedia
              component="img"
              height="194"
              image={`${GlobalData.serverURL}/icon/toptalk_logo_nobg.svg`}
              alt={title}
              sx={{ objectFit: 'contain' }}
            />
            <CardContent>
              <Typography gutterBottom variant="h6" component="div">
                转码中...
              </Typography>
              <Typography gutterBottom variant="body1" component="div" maxlines={2} overflow="ellipsis" >
                {originalFilename}
              </Typography>
            </CardContent>
          </>
        );
      } else {
        return (
          <>
            <CardMedia
              component="img"
              height="194"
              image={thumbnailUrl}
              alt={title}
              onClick={handleClick}
            />
            <CardContent>
              <Typography
                gutterBottom
                variant="body1"
                component="div"
                onClick={handleClick}
                sx={{
                  display: '-webkit-box',
                  WebkitLineClamp: 2,
                  WebkitBoxOrient: 'vertical',
                  overflow: 'hidden'
                }}
              >
                {title === null ? originalFilename : title}

              </Typography>
              {title === null && (
                <Typography variant="body2" color="textSecondary" component="p" onClick={handleClick}>
                  教案生成中...
                </Typography>
              )}
              {title !== null && (
                <Button variant="outlined" size="small" onClick={handleEditClick}>
                  编辑教案
                </Button>
              )}
            </CardContent>
          </>
        );
      }
    } else if (pageType === 'others' && isTranscode === 1) {  // "其他视频" 页面，只显示转码完成的视频
      return (
        <>
          <CardMedia
            component="img"
            height="194"
            image={thumbnailUrl}
            alt={title}
            onClick={handleClick}
          />
          <CardContent>
            <Typography gutterBottom variant="body1" component="div" onClick={handleClick}>
              {title}
            </Typography>
          </CardContent>
        </>
      );
    } else {  // 其他情况不显示任何内容
      return null;
    }
  };

  return (
    <Card sx={{ maxWidth: 345, cursor: 'pointer', elevation: 0 }} >
      {renderCardContent()}
    </Card>
  );
};

export default VideoCard;