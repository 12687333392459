import React, { useEffect, useRef, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { GlobalData } from '../global_data';
import styled from '@emotion/styled';

// 使用 styled components 定义样式
const VideoContainer = styled.div`
  width: 80%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f5f5f5;
  margin: 0 auto;
  padding: 0;
  position: relative;
  aspect-ratio: 16 / 9;
`;

const VideoWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ErrorMessage = styled.div`
  color: red;
  text-align: center;
  padding: 20px;
`;

// 修改视频播放器样式
const VideoPlayer = styled.video`
  width: 100%;
  height: 100%;
  object-fit: contain; // 保持视频比例，避免拉伸
  background: transparent; // 移除黑色背景
`;

// 添加词典弹窗样式
const DictionaryPopup = styled.div`
  position: fixed;
  background: rgba(255, 255, 255, 0.98);
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 15px;
  min-width: 250px;
  max-width: 400px;
  box-shadow: 0 4px 12px rgba(0,0,0,0.15);
  z-index: 1000;
  display: ${props => props.show ? 'block' : 'none'};
  left: ${props => props.x}px;
  top: ${props => props.y}px;
  
  // 添加以下样式确保弹窗始终在可视区域内
  max-height: 300px;
  overflow-y: auto;
  
  // 如果弹窗位置超出视窗顶部，则显示在单词下方
  ${props => props.y < 0 && `
    top: ${props.y + 340}px;
  `}
`;

// 添加自定义字幕容器样式
const SubtitleContainer = styled.div`
  position: absolute;
  bottom: 60px;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  z-index: 2;
  pointer-events: auto;
  user-select: none;
  width: 100%;
`;

// 修改 SubtitleText 样式组件，添加一个基础样式
const SubtitleBase = styled.span`
  background: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 5px 2px;
  margin: 0;
  display: inline-block;
`;

// SubtitleText 继承 SubtitleBase 的样式
const SubtitleText = styled(SubtitleBase)`
  ${props => props.clickable && `
    cursor: pointer;
    &:hover {
      background: rgba(0, 0, 0, 0.9);
    }
  `}
`;

// 添加样式组件用于格式化显示
const WordTitle = styled.h3`
  margin: 0 0 8px 0;
  color: #333;
  font-size: 18px;
`;

const Phonetic = styled.p`
  color: #666;
  margin: 0 0 8px 0;
  font-style: italic;
`;

const Translation = styled.div`
  margin: 8px 0;
  padding: 8px;
  background: #f5f5f5;
  border-radius: 4px;
`;

const Definition = styled.div`
  margin: 8px 0;
  line-height: 1.4;
`;

// 修改分词函数，返回单词和非单词部分
const splitIntoWords = (text) => {
  // 使用正则表达式匹配英文单词和其他字符
  const pattern = /([a-zA-Z]+[-'']?[a-zA-Z]+|[^a-zA-Z\s]+|\s+)/g;
  const matches = text.match(pattern) || [];

  // 返回带类型的标记数组
  return matches.map(token => ({
    text: token,
    // 判断是否为可点击的单词
    isWord: /^[a-zA-Z]+[-'']?[a-zA-Z]+$/.test(token) && token.length >= 2,
    // 判断是否为空白字符
    isSpace: /^\s+$/.test(token)
  }));
};

// 添加全屏按钮样式
const FullscreenButton = styled.button`
  position: absolute;
  right: 10px;
  bottom: 10px;
  background: rgba(0, 0, 0, 0.6);
  color: white;
  border: none;
  border-radius: 4px;
  padding: 8px;
  cursor: pointer;
  z-index: 3;
  
  &:hover {
    background: rgba(0, 0, 0, 0.8);
  }
`;

// 将 VideoContent 定义为独立的组件
const VideoContent = React.memo(({
  videoRef,
  videoUrl,
  subtitleUrl,
  subtitleWords,
  handleVideoError,
  handleWordClick,
  setCurrentSubtitle,
  setSubtitleWords,
  splitIntoWords
}) => {
  const wrapperRef = useRef(null);
  const [isFullscreen, setIsFullscreen] = useState(false);

  // 监听全屏状态变化
  useEffect(() => {
    const wrapper = wrapperRef.current;
    if (!wrapper) return;

    const handleFullscreenChange = () => {
      const isFullscreen = (
        document.fullscreenElement === wrapper ||
        document.webkitFullscreenElement === wrapper ||
        document.mozFullScreenElement === wrapper ||
        document.msFullscreenElement === wrapper
      );

      setIsFullscreen(isFullscreen);

      // 调整字幕容器的样式
      const subtitleContainer = document.querySelector('.subtitle-container');
      if (subtitleContainer) {
        if (isFullscreen) {
          subtitleContainer.style.position = 'fixed';
          subtitleContainer.style.zIndex = '9999';
          subtitleContainer.style.bottom = '60px';
        } else {
          subtitleContainer.style.position = 'absolute';
          subtitleContainer.style.zIndex = '2';
          subtitleContainer.style.bottom = '60px';
        }
      }
    };

    document.addEventListener('fullscreenchange', handleFullscreenChange);
    document.addEventListener('webkitfullscreenchange', handleFullscreenChange);
    document.addEventListener('mozfullscreenchange', handleFullscreenChange);
    document.addEventListener('MSFullscreenChange', handleFullscreenChange);

    return () => {
      document.removeEventListener('fullscreenchange', handleFullscreenChange);
      document.removeEventListener('webkitfullscreenchange', handleFullscreenChange);
      document.removeEventListener('mozfullscreenchange', handleFullscreenChange);
      document.removeEventListener('MSFullscreenChange', handleFullscreenChange);
    };
  }, []);

  const handleFullscreenClick = async () => {
    const wrapper = wrapperRef.current;
    if (!wrapper) return;

    try {
      if (!document.fullscreenElement) {
        if (wrapper.requestFullscreen) {
          await wrapper.requestFullscreen();
        } else if (wrapper.webkitRequestFullscreen) {
          await wrapper.webkitRequestFullscreen();
        } else if (wrapper.mozRequestFullScreen) {
          await wrapper.mozRequestFullScreen();
        } else if (wrapper.msRequestFullscreen) {
          await wrapper.msRequestFullscreen();
        }
      } else {
        if (document.exitFullscreen) {
          await document.exitFullscreen();
        } else if (document.webkitExitFullscreen) {
          await document.webkitExitFullscreen();
        } else if (document.mozCancelFullScreen) {
          await document.mozCancelFullScreen();
        } else if (document.msExitFullscreen) {
          await document.msExitFullscreen();
        }
      }
    } catch (err) {
      console.error('全屏切换失败:', err);
    }
  };

  return (
    <VideoWrapper ref={wrapperRef}>
      <VideoPlayer
        ref={videoRef}
        controls
        onError={handleVideoError}
        onLoadedData={() => console.log('视频加载完成')}
        playsInline
      >
        <source src={videoUrl} type="video/mp4" />
        {subtitleUrl && (
          <track
            label="字幕"
            kind="subtitles"
            srcLang="zh"
            src={subtitleUrl}
            mode="hidden"
            onLoad={() => {
              console.log('字幕轨道加载完成');
              if (videoRef.current) {
                const track = videoRef.current.textTracks[0];
                if (track) track.mode = 'hidden';
              }
            }}
            onError={(e) => console.error('字幕轨道加载失败:', e)}
          />
        )}
      </VideoPlayer>

      <FullscreenButton onClick={handleFullscreenClick}>
        {isFullscreen ? '退出全屏' : '全屏'}
      </FullscreenButton>

      <SubtitleContainer className="subtitle-container">
        {subtitleWords.map((token, index) => (
          token.isSpace ? (
            <SubtitleBase key={index}>&nbsp;</SubtitleBase>
          ) : (
            <SubtitleText
              key={index}
              onClick={(e) => token.isWord ? handleWordClick(token.text, e) : null}
              clickable={token.isWord}
            >
              {token.text}
            </SubtitleText>
          )
        ))}
      </SubtitleContainer>
    </VideoWrapper>
  );
});

const PlayVideo = () => {
  const { videoFilename } = useParams();
  const location = useLocation();
  const videoRef = useRef(null);
  const [videoUrl, setVideoUrl] = useState('');
  const [subtitleUrl, setSubtitleUrl] = useState('');
  const [error, setError] = useState(null);

  // 添加字幕加载状态追踪
  const [subtitleStatus, setSubtitleStatus] = useState('未加载');

  const [wordInfo, setWordInfo] = useState(null);
  const [popupPosition, setPopupPosition] = useState({ x: 0, y: 0 });
  const [showPopup, setShowPopup] = useState(false);
  const [currentSubtitle, setCurrentSubtitle] = useState('');
  const [subtitleWords, setSubtitleWords] = useState([]);

  useEffect(() => {
    const fetchVideo = async () => {
      try {
        if (!videoFilename) {
          throw new Error('未提供视频文件名');
        }

        console.log('开始加载视频:', videoFilename);
        setVideoUrl(`${GlobalData.serverURL}/getvideofile/${videoFilename}`);

        // 尝试获取字幕文件
        try {
          console.log('开始请求字幕文件...');
          setSubtitleStatus('正在加载字幕');

          const subtitleUrl = `${GlobalData.serverURL}/get_subtitle/${videoFilename}`;
          console.log('字幕请求URL:', subtitleUrl);

          const subtitleResponse = await fetch(subtitleUrl);
          console.log('字幕响应状态:', subtitleResponse.status);

          if (subtitleResponse.ok) {
            const subtitleBlob = await subtitleResponse.blob();
            console.log('字幕文件类型:', subtitleBlob.type);

            const subtitleObjectUrl = URL.createObjectURL(subtitleBlob);
            setSubtitleUrl(subtitleObjectUrl);
            setSubtitleStatus('字幕加载成功');
            console.log('字幕URL已设置:', subtitleObjectUrl);
          } else {
            setSubtitleStatus('未找到字幕文件');
            console.log('字幕请求失败:', await subtitleResponse.text());
          }
        } catch (subtitleError) {
          setSubtitleStatus('字幕加载失败');
          console.error('字幕加载错误:', subtitleError);
        }

      } catch (err) {
        console.error('获取视频失败:', err);
        setError(err.message);
      }
    };

    fetchVideo();

    // 清理函数
    return () => {
      if (subtitleUrl) {
        URL.revokeObjectURL(subtitleUrl);
      }
    };
  }, [videoFilename]);

  // 修改处理单词点击的函数
  const handleWordClick = async (word, event) => {
    console.log('单词被点击:', word);
    try {
      // 修改清理逻辑，只移除除撇号外的标点符号
      const cleanWord = word
        .replace(/[.,!?;:"]/g, '')  // 移除标点符号，但保留撇号
        .toLowerCase();  // 转换为小写

      console.log('处理后的单词:', cleanWord);

      const response = await fetch(`${GlobalData.serverURL}/query_word/${cleanWord}`);
      console.log('查询响应状态:', response.status);

      if (response.ok) {
        const data = await response.json();
        console.log('查询结果:', data);
        setWordInfo(data);

        // 获取点击元素的位置信息
        const rect = event.target.getBoundingClientRect();

        // 计算弹窗位置，显示在单词上方
        setPopupPosition({
          x: rect.left,
          y: rect.top - 320
        });

        setShowPopup(true);
      } else {
        console.log('未找到单词释义');
      }
    } catch (error) {
      console.error('查询单词失败:', error);
    }
  };

  // 添加字幕轨道加载事件处理
  const handleTrackLoad = () => {
    console.log('字幕轨道已加载');
  };

  const handleTrackError = (e) => {
    console.error('字幕轨道加载失败:', e);
    setSubtitleStatus('字幕轨道加载失败');
  };

  const handleVideoError = (e) => {
    console.error('视频加载错误:', e);
    setError('视频加载失败，请稍后重试');
  };

  // 点击视频外部区域关闭弹窗
  const handleClickOutside = (e) => {
    if (!e.target.closest('.dictionary-popup')) {
      setShowPopup(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  if (error) {
    return <ErrorMessage>{error}</ErrorMessage>;
  }

  // 添加字幕状态显示（调试用）
  console.log('当前字幕状态:', subtitleStatus);
  console.log('字幕URL:', subtitleUrl);

  return (
    <>
      <VideoContainer onClick={handleClickOutside}>
        {videoUrl && (
          <VideoContent
            videoRef={videoRef}
            videoUrl={videoUrl}
            subtitleUrl={subtitleUrl}
            subtitleWords={subtitleWords}
            handleVideoError={handleVideoError}
            handleWordClick={handleWordClick}
            setCurrentSubtitle={setCurrentSubtitle}
            setSubtitleWords={setSubtitleWords}
            splitIntoWords={splitIntoWords}
          />
        )}
      </VideoContainer>

      {/* 词典弹窗 */}
      <DictionaryPopup
        className="dictionary-popup"
        show={showPopup}
        x={popupPosition.x}
        y={popupPosition.y}
      >
        {wordInfo && (
          <>
            <WordTitle>{wordInfo.word}</WordTitle>
            {wordInfo.phonetic && <Phonetic>[{wordInfo.phonetic}]</Phonetic>}
            {wordInfo.pos && <div>词性: {wordInfo.pos}</div>}
            {wordInfo.translation && (
              <Translation>
                <strong>翻译:</strong> {wordInfo.translation}
              </Translation>
            )}
            {wordInfo.definition && (
              <Definition>
                <strong>释义:</strong> {wordInfo.definition}
              </Definition>
            )}
            {wordInfo.collins && <div>柯林斯星级: {wordInfo.collins}</div>}
          </>
        )}
      </DictionaryPopup>
    </>
  );
};

export default PlayVideo; 