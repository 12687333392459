import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { GlobalData } from '../global_data';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import { Box, IconButton } from '@mui/material';

const TypingText = ({
  comments,
  ratings,
  followUpQuestion,
  style,
  duration = 1000,
  curve = 'linear',
  autoPlay = false,
  audioPlayer,
  needSave = true,
  videoFileName,
  onTypingComplete
}) => {
  const [displayedComments, setDisplayedComments] = useState('');
  const [displayedRatings, setDisplayedRatings] = useState('');
  const [displayedFollowUp, setDisplayedFollowUp] = useState('');
  const [isCommentsComplete, setIsCommentsComplete] = useState(false);
  const [isRatingsComplete, setIsRatingsComplete] = useState(false);
  const [audioUrl, setAudioUrl] = useState(null);
  const textRef = useRef(null);
  const lastHeightRef = useRef(0);

  // 将评分文本转换为星星图标
  const renderStars = (ratingText) => {
    if (!ratingText) return null;

    const lines = ratingText.split('\n');
    return lines.map((line, index) => {
      if (!line.includes('Stars:')) return line;

      const [label, stars] = line.split(':');
      const starCount = (stars.match(/⭐/g) || []).length;

      return (
        <Box key={index} sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
          <span>{label}:</span>
          <Box sx={{ display: 'flex' }}>
            {[...Array(5)].map((_, i) => (
              i < starCount ? (
                <StarIcon
                  key={i}
                  sx={{
                    fontSize: 16,
                    color: '#ffbb34',  // 实心星星保持橙色
                    filter: 'drop-shadow(0px 0px 1px white) drop-shadow(0px 0px 1px white)',  // 实心星星保持白色边框
                  }}
                />
              ) : (
                <StarBorderIcon
                  key={i}
                  sx={{
                    fontSize: 16,
                    color: 'white',  // 空心星星为白色
                  }}
                />
              )
            ))}
          </Box>
        </Box>
      );
    });
  };

  // 检查高度变化并触发滚动
  const checkHeightAndScroll = () => {
    if (textRef.current) {
      const currentHeight = textRef.current.getBoundingClientRect().height;
      if (currentHeight > lastHeightRef.current) {
        lastHeightRef.current = currentHeight;
        if (onTypingComplete) {
          onTypingComplete();
        }
      }
    }
  };

  // 处理评论文本动画
  useEffect(() => {
    if (!comments) return;

    let timer;
    const charDuration = duration / comments.length;
    let currentCharIndex = 0;

    const animateComments = () => {
      if (currentCharIndex < comments.length) {
        setDisplayedComments(prev => {
          const newText = comments.substring(0, currentCharIndex + 1);
          // 检查是否有换行符或达到容器宽度
          requestAnimationFrame(checkHeightAndScroll);
          return newText;
        });
        currentCharIndex++;
        timer = setTimeout(animateComments, charDuration);
      } else {
        setIsCommentsComplete(true);
        checkHeightAndScroll();
      }
    };

    animateComments();
    return () => clearTimeout(timer);
  }, [comments, duration]);

  // 处理评分文本动画
  useEffect(() => {
    if (!ratings || !isCommentsComplete) return;

    let timer;
    const charDuration = duration / ratings.length;
    let currentCharIndex = 0;

    const animateRatings = () => {
      if (currentCharIndex < ratings.length) {
        setDisplayedRatings(prev => {
          const newText = ratings.substring(0, currentCharIndex + 1);
          // 检查是否有换行
          requestAnimationFrame(checkHeightAndScroll);
          return newText;
        });
        currentCharIndex++;
        timer = setTimeout(animateRatings, charDuration);
      } else {
        setIsRatingsComplete(true);
        checkHeightAndScroll();
      }
    };

    animateRatings();
    return () => clearTimeout(timer);
  }, [ratings, isCommentsComplete, duration]);

  // 处理后续问题动画
  useEffect(() => {
    if (!followUpQuestion || !isRatingsComplete) return;

    let timer;
    const charDuration = duration / followUpQuestion.length;
    let currentCharIndex = 0;

    const animateFollowUp = () => {
      if (currentCharIndex < followUpQuestion.length) {
        setDisplayedFollowUp(prev => {
          const newText = followUpQuestion.substring(0, currentCharIndex + 1);
          requestAnimationFrame(checkHeightAndScroll);
          return newText;
        });
        currentCharIndex++;
        timer = setTimeout(animateFollowUp, charDuration);
      } else {
        checkHeightAndScroll();
      }
    };

    animateFollowUp();
    return () => clearTimeout(timer);
  }, [followUpQuestion, isRatingsComplete, duration]);

  // 音频相关代码
  useEffect(() => {
    const fetchAudio = async () => {
      if (!comments) return;

      try {
        // 如果有后续问题，将评论和后续问题合并为一段文本
        const audioText = followUpQuestion ?
          `${comments} \n${followUpQuestion}` :
          comments;

        const response = await axios.post(`${GlobalData.serverURL}/get_audio/`, {
          text: audioText,
          video_filename: videoFileName,
          need_save: needSave
        }, { responseType: 'blob' });

        if (response.status === 200) {
          // 创建URL对象
          const blob = new Blob([response.data], { type: 'audio/mpeg' });
          const url = URL.createObjectURL(blob);
          setAudioUrl(url);
          if (autoPlay && audioPlayer) {
            audioPlayer.src = url;
            audioPlayer.play();
          }
        }
      } catch (error) {
        console.error('获取音频失败', error);
      }
    };

    fetchAudio();

    return () => {
      if (audioUrl) {
        URL.revokeObjectURL(audioUrl);
      }
    };
  }, [comments, followUpQuestion, videoFileName, needSave, autoPlay, audioPlayer]);

  // 添加重播音频的函数
  const handleReplay = () => {
    if (audioPlayer && audioUrl) {
      audioPlayer.src = audioUrl;
      audioPlayer.play();
    }
  };

  return (
    <Box
      ref={textRef}
      sx={{
        ...style,
        display: 'flex',
        alignItems: 'flex-start',
        gap: 0  // 将间距设为0
      }}
    >
      <Box sx={{
        flex: 1,
        display: 'inline-block',  // 使用 inline-block 以避免额外空间
        paddingRight: '4px',  // 只在右侧添加少量间距
        paddingLeft: '4px'  // 只在左侧添加少量间距
      }}>
        {displayedComments}
        {renderStars(displayedRatings)}
        {displayedFollowUp}
      </Box>
      {audioUrl && (  // 只有在有音频时才显示按钮
        <IconButton
          onClick={handleReplay}
          size="small"
          sx={{
            padding: '2px',  // 减小按钮内边距
            minWidth: '20px',  // 设置最小宽度
            height: '20px',  // 固定高度
            color: 'white',
            '&:hover': {
              backgroundColor: 'rgba(255, 255, 255, 0.1)'
            },
            flexShrink: 0
          }}
        >
          <VolumeUpIcon sx={{ fontSize: '16px' }} />
        </IconButton>
      )}
    </Box>
  );
};

export default TypingText;