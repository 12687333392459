import React, { useState, useEffect, useRef, useCallback } from 'react';
import {
  Box,
  Typography,
  IconButton,
  Dialog,
  Rating,
  AppBar,
  Toolbar,
  Tooltip,
  ToggleButtonGroup,
  ToggleButton,
  Switch,
} from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import MicIcon from '@mui/icons-material/Mic';
import StopIcon from '@mui/icons-material/Stop';
import CloseIcon from '@mui/icons-material/Close';
import TranslateIcon from '@mui/icons-material/Translate';
import LanguageIcon from '@mui/icons-material/Language';
import axios from 'axios';
import { GlobalData } from '../global_data';
import TypingText from './TypingText'; // 引入打字机效果组件
import BlinkingText from './BlinkingText'; // 引入闪烁文本组件
import { debounce } from 'lodash';  // 添加 lodash 依赖

const useResizeObserver = (callback) => {
  const ref = useRef(null);

  useEffect(() => {
    if (ref.current) {
      const observer = new ResizeObserver((entries) => {
        callback(entries[0]);
      });

      observer.observe(ref.current);
      return () => observer.disconnect();
    }
  }, [callback]);

  return ref;
};

function QuestionInteraction({ videoFilename, segment, summary, onClose, onQuestionAnswered }) {
  const [isRecording, setIsRecording] = useState(false); // 是否录音状态
  const [feedback, setFeedback] = useState(null);
  const [conversation, setConversation] = useState([]);
  const audioPlayer = useRef(new Audio());  // 初始化音频播放器
  const ws = useRef(null); // WebSocket 实例
  const mediaRecorder = useRef(null); // 保存 MediaRecorder 实例
  const stream = useRef(null); // 保存音频流
  const conversationEndRef = useRef(null); // 用于滚动到对话底部
  const recordingRef = useRef(false); // 用于控制录音的开始和停止
  const [isProcessing, setIsProcessing] = useState(false);  // 添加处理中状态
  const [asrModel, setAsrModel] = useState('english'); // 'english' 或 'mixed'
  const [chatHistory, setChatHistory] = useState([]);

  // 添加新的滚动处理函数
  const smoothScrollToBottom = useCallback(() => {
    requestAnimationFrame(() => {
      if (conversationEndRef.current) {
        conversationEndRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    });
  }, []);

  // 使用 ResizeObserver 监听对话内容变化
  const resizeCallback = useCallback((entry) => {
    smoothScrollToBottom();
  }, [smoothScrollToBottom]);

  const conversationBoxRef = useResizeObserver(resizeCallback);

  // 使用防抖处理点击事件
  const handleMicButtonClick = useCallback(
    debounce(() => {
      if (isProcessing) return;  // 如果正在处理中，忽略点击
      setIsProcessing(true);     // 设置处理中状态
      setIsRecording(prev => !prev);
      recordingRef.current = !recordingRef.current;
      if (audioPlayer.current.paused === false) {
        audioPlayer.current.pause();
      }
      setTimeout(() => setIsProcessing(false), 500);  // 500ms 后解除锁定
    }, 300),
    [isProcessing]
  );

  // 控制录音的开始和停止
  useEffect(() => {
    try {
      if (isRecording) {
        //console.log('isRecording changed to:', isRecording)
        startRecording();
      } else {
        //console.log('isRecording changed to:', isRecording)
        stopRecording();
      }
    } catch (error) {
      console.log(error);
    }
    return () => {
      //stopRecording();
    }
  }, [isRecording]);

  // 滚动到对话列表的底部
  useEffect(() => {
    // 在每次对话内容更新后滚动到底部
    if (conversationEndRef.current) {
      conversationEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [conversation]);

  // 处理对话框高度变化的回调函数
  const handleHeightChange = useCallback(() => {
    // 在每次对话内容更新后滚动到底部
    //console.log('handleHeightChange called');
    if (conversationEndRef.current) {
      conversationEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);

  // 开始录音函数
  const startRecording = () => {
    try {
      //console.log('开始录音');
      initWebSocket();// 初始化 WebSocket 连接(包括连接成功后启动录音)
    } catch (error) {
      console.error('Error starting recording:', error);
    }
  };

  // 停止录音函数
  const stopRecording = () => {
    if (!mediaRecorder.current && !stream.current) return;  // 如果已经停止，直接返回

    try {
      //console.log('停止录音');
      if (mediaRecorder.current) {
        //console.log('停止mediaRecorder');
        mediaRecorder.current.stop();
        mediaRecorder.current = null;
      }
      if (stream.current) {
        //console.log('停止stream');
        stream.current.getTracks().forEach(track => track.stop());
        stream.current = null;
      }
      // 确保 WebSocket 连接正确关闭
      if (ws.current?.readyState === WebSocket.OPEN) {
        sendEndofAudioMessage();
      } else if (ws.current) {
        ws.current.close();
      }
    } catch (error) {
      console.error('Error stopping recording:', error);
    }
  };

  // 初始化 WebSocket 连接
  const initWebSocket = () => {
    // 确保旧连接已关闭
    if (ws.current) {
      ws.current.close();
      ws.current = null;
    }

    try {
      ws.current = new WebSocket(`wss://www.toptalk.vip:8000/ws/asr`);

      ws.current.onopen = () => {
        //console.log('WebSocket connected');
        if (ws.current?.readyState === WebSocket.OPEN) {
          // 发送模型选择消息
          ws.current.send(JSON.stringify({
            type: 'model_selection',
            model_type: asrModel
          }));

          sendStartRecordingMessage();
          initMediaRecorder();
          setConversation(prev => [...prev, { sender: 'user', text: '...' }]);
        }
      };

      ws.current.onmessage = (event) => {
        try {
          const message = JSON.parse(event.data);
          //console.log('Received message from server:', message);
          if (message.type === 'recognition_result') {
            if (message.is_final) {
              ws.current?.close();
              getFeedbackFromLLM(message.transcript);
            }
            setConversation((prevConversation) => {
              const newMessage = {
                sender: 'user',
                text: message.transcript,
                isFinal: message.is_final,
              };
              return prevConversation.slice(0, -1).concat(newMessage);
            });
          }
        } catch (error) {
          console.error('Error parsing server message:', error);
        }

      };

      ws.current.onclose = () => {
        //console.log('WebSocket disconnected');
        ws.current = null;  // 清除引用
      };

      ws.current.onerror = (error) => {
        console.error('WebSocket error:', error);
        if (!recordingRef.current) {
          stopRecording();
          ws.current?.close();
        }
      };
    } catch (error) {
      console.error('Error initializing WebSocket:', error);
      setIsProcessing(false);  // 发生错误时解除锁定
    }
  };

  // 初始化 MediaRecorder
  const initMediaRecorder = () => {
    navigator.mediaDevices.getUserMedia({ audio: true })
      .then((userStream) => {
        stream.current = userStream;
        mediaRecorder.current = new MediaRecorder(stream.current);

        //处理录音数据事件
        mediaRecorder.current.ondataavailable = (event) => {
          // console.log('Audio data available');
          try {
            if (event.data.size > 0) {
              const reader = new FileReader();
              reader.onload = () => {
                const arrayBuffer = reader.result;
                const base64String = btoa(
                  String.fromCharCode(...new Uint8Array(arrayBuffer))
                );
                if (recordingRef.current) {
                  sendAudioDataMessage(base64String); // 发送音频数据消息
                  //console.log('Audio data sent,isRecording:', isRecording);
                }
                else {
                  //console.log('录音途中检测到button状态已经改变');
                  stopRecording();
                }
              };
              reader.readAsArrayBuffer(event.data);
            }
          } catch (error) {
            console.error('Error reading audio data:', error);
          }
        };

        //处理录音停止事件
        mediaRecorder.current.onstop = () => {
          setTimeout(() => {
            sendEndofAudioMessage(); // 停止录音时发送结束录音消息
          }, 100);
        };

        //开始录音
        mediaRecorder.current.start(100);
      })
      .catch(err => {
        console.error("Error accessing microphone:", err);
      });
  };

  // 发送开始录音消息
  const sendStartRecordingMessage = () => {
    try {
      ws.current.send(JSON.stringify({
        type: 'start_recording',
        format: 'pcm',
        rate: 16000,
        bits: 16,
        channels: 1,
      }));
    } catch (error) {
      console.error('Error sending start_recording message:', error);
      stopRecording();
      if (ws.current) {
        ws.current?.close();
      }
    }
  };

  // 发送音频数据消息
  const sendAudioDataMessage = (base64String) => {
    try {
      if (ws.current.readyState === WebSocket.OPEN) {
        ws.current?.send(JSON.stringify({
          type: 'audio_data',
          data: base64String,
        }));
      }
    } catch (error) {
      console.error('Error sending audio_data message:', error);
      stopRecording();

    }
  };

  // 发送结束录音消息
  const sendEndofAudioMessage = () => {
    try {
      setTimeout(() => {
        // 检查 WebSocket 的状态
        if (ws.current && ws.current.readyState === WebSocket.OPEN) {
          ws.current.send(JSON.stringify({
            type: 'end_of_audio',
          }));
          //console.log("前端发送给后端消息:end_of_audio");
        } else {
          //console.log('WebSocket 未连接，无法送 end_of_audio 消息');
          if (ws.current) {
            ws.current.close();
          }
        }
      }, 100);
    }
    catch (error) {
      console.error('Error sending end_of_audio message:', error);
      stopRecording();
    }
  };




  // 将用户语音输入转录的文本交给大模型判断，并获取反馈
  const getFeedbackFromLLM = async (finalTranscript) => {
    if (finalTranscript) {
      let prompt;

      // 如果是首次对话,使用完整的角色设定提示词
      if (chatHistory.length === 0) {
        prompt = `
          你的角色：你是一个英语口语学习助手，面向的用户是4-10岁的孩子。孩子学习的方式是观看一个视频，视频是有对应的教案。教案内容包括：视频内容（Summary）、问题相关片段的描述（Segment description）、向孩子提出的问题（Question）、问题的参考答案（Reference Answer）。

          你的任务：你需要根据教案内容以及孩子的回答（Child Answer），给孩子做出反馈，并结合提出进一步的问题，引导孩子进行口语沟通。注意沟通内容不要偏离教案太远。
          你的输入：
          1.Summary: ${summary}
          2.Segment description: ${segment.description}
          3.Question: ${segment.question}
          4.Reference Answer: ${segment.answer}
          5.Child Answer: ${finalTranscript}

          你的输出(必须使用英文):
          {
            "Content_Accuracy": "(0-5, integer)",
            "Grammar_Accuracy": "(0-5, integer,忽略字母拼写及大小写错误。)",
            "Comments": "(简短的评价。如果孩子遇到困难，需要给与适当帮助。忽略字母拼写及大小写错误。)",
            "Follow_up_Question": "(If Content_Accuracy >= 4, provide a related follow-up question to extend the conversation. Otherwise, null)"
          }
        `;
        setChatHistory(prev => [...prev, { role: "user", parts: prompt }]);
      } else {
        // 后续对话只需要发送用户回答
        prompt = `Child Answer: ${finalTranscript}`;
      }

      try {
        const response = await axios.post(`${GlobalData.serverURL}/llm_run/`, {
          prompt,
          history: chatHistory
        });

        if (response.status === 200) {
          const feedbackData = {
            ...response.data,
            Content_Accuracy: Number(response.data.Content_Accuracy),
            Grammar_Accuracy: Number(response.data.Grammar_Accuracy)
          };

          setFeedback(feedbackData);

          const getStars = (count) => {
            const filledStars = '⭐'.repeat(count);
            const emptyStars = '☆'.repeat(5 - count);
            return filledStars + emptyStars;
          };

          const comments = feedbackData.Comments;
          const ratings = `\nContent Stars: ${getStars(feedbackData.Content_Accuracy)}\n` +
            `Grammar Stars: ${getStars(feedbackData.Grammar_Accuracy)}`;

          // 添加反馈和后续问题到对话中
          let botMessage = {
            sender: 'bot',
            comments: comments,
            ratings: ratings,
            followUpQuestion: feedbackData.Follow_up_Question,  // 添加后续问题
            style: { fontSize: '16px', whiteSpace: 'pre-wrap' }
          };

          setConversation(prev => [...prev, botMessage]);

          // 更新对话历史
          setChatHistory(prev => [
            ...prev,
            { role: "user", parts: finalTranscript },
            { role: "model", parts: JSON.stringify(feedbackData) }
          ]);

          // 保存问答记录
          if (onQuestionAnswered && typeof onQuestionAnswered === 'function') {
            const questionData = {
              question: segment.question,
              answer: finalTranscript,
              contentAccuracy: Number(feedbackData.Content_Accuracy),
              grammarAccuracy: Number(feedbackData.Grammar_Accuracy),
              feedback: feedbackData.Comments
            };

            //console.log('调用 onQuestionAnswered 函数，传递数据:', questionData);

            if (questionData.question && questionData.answer) {  // 确保有问题和答案
              onQuestionAnswered(questionData);
            } else {
              console.warn('问答数据不完整，跳过保存');
            }
          } else {
            console.warn('onQuestionAnswered 函数未定义或不是函数类型');
          }
        }
      } catch (error) {
        console.error('Error getting feedback from LLM:', error);
      }
    }
  };


  // 渲染对话列表
  const renderConversation = () => {
    return conversation.map((message, index) => (
      <Box
        key={index}
        sx={{
          display: 'flex',
          justifyContent: message.sender === 'user' ? 'flex-end' : 'flex-start',
          marginBottom: '8px',
          maxWidth: '100%', // 限制消息框的最大宽度
        }}
      >
        <Box
          sx={{
            padding: '8px',
            borderRadius: '8px',
            backgroundColor: message.sender === 'user' ? '#b3e162' : '#71ccff', // 根据发送者设置背景色
            color: 'white',
            maxWidth: '80%', // 设置最大宽度为 80%
          }}
        >
          {message.sender === 'bot' ? (
            <TypingText
              comments={message.comments}
              ratings={message.ratings}
              followUpQuestion={message.followUpQuestion}
              style={message.style}
              duration={2000}
              autoPlay={true}
              audioPlayer={audioPlayer.current}
              needSave={false}
              videoFileName={videoFilename}
              onTypingComplete={smoothScrollToBottom}
            />
          ) : (
            <Typography component="span" variant="body1" style={{ fontSize: '16px', whiteSpace: 'pre-wrap' }}>
              {message.text}
            </Typography>
          )}
        </Box>
      </Box>
    ));
  };

  // 停止音频播放，并调起onclose
  const handleClose = () => {
    audioPlayer.current?.pause();
    onClose();
  }

  useEffect(() => {
    return () => {
      if (ws.current) {
        ws.current.close();
        ws.current = null;
      }
      if (mediaRecorder.current) {
        mediaRecorder.current.stop();
        mediaRecorder.current = null;
      }
      if (stream.current) {
        stream.current.getTracks().forEach(track => track.stop());
        stream.current = null;
      }
    };
  }, []);

  return (
    <Dialog
      open
      onClose={() => { }} // 屏蔽点击对话框外部关闭的功能
      fullWidth
      maxWidth="md"
      PaperProps={{
        sx: {
          height: '90%',
          width: '60%',
          minWidth: 600,
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden',
        },
      }}
    >
      <Box sx={{ flexShrink: 0 }}>
        <AppBar sx={{ position: 'relative', backgroundColor: 'white', boxShadow: 'none' }}>
          <Toolbar>
            {/* 添加图标和标题的容器 */}
            <Box sx={{ display: 'flex', alignItems: 'center', flex: 1 }}>
              <img
                src={`${GlobalData.serverURL}/icon/toptalk_logo_nobg.svg`}
                alt="TopTalk Logo"
                width="32"
                height="32"
                style={{ marginRight: '8px' }}
              />
              <Typography
                sx={{
                  color: '#ffbb34',
                  fontWeight: 'bold'  // 添加粗体
                }}
                variant="h6"
                component="div"
              >
                Question
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', mr: 2 }}>
              <Typography
                variant="body2"
                sx={{
                  mr: 1,
                  color: '#ffbb34',  // 改为橙色
                  display: 'flex',
                  alignItems: 'center'
                }}
              >
                识别中文
                <Tooltip
                  title="开启后可以识别中文回答，但可能会降低英文识别准确度"
                  placement="left"
                  slotProps={{
                    tooltip: {
                      sx: {
                        bgcolor: '#f5f5f5',  // 使用非常浅的灰色背景
                        color: '#666',       // 文字颜色
                        border: '1px solid rgba(0, 0, 0, 0.05)',  // 极浅的边框
                        boxShadow: '0 2px 8px rgba(0, 0, 0, 0.08)',  // 轻微的阴影
                        fontSize: '12px',
                        padding: '8px 12px',
                        '& .MuiTooltip-arrow': {
                          color: '#f5f5f5'  // 箭头颜色与背景色匹配
                        }
                      }
                    }
                  }}
                >
                  <HelpOutlineIcon
                    sx={{
                      fontSize: 16,
                      ml: 0.5,
                      color: '#ffbb34'  // 改为橙色
                    }}
                  />
                </Tooltip>
              </Typography>
              <Switch
                checked={asrModel === 'mixed'}
                onChange={(e) => setAsrModel(e.target.checked ? 'mixed' : 'english')}
                sx={{
                  '& .MuiSwitch-switchBase': {
                    color: 'grey.200',  // 未选中状态的按钮颜色（灰色）
                    '&.Mui-checked': {
                      color: '#ffbb34', // 选中状态的按钮颜色（橙色）
                      '& + .MuiSwitch-track': {
                        backgroundColor: '#ffbb34 !important', // 选中状态的轨道颜色（橙色）
                        opacity: 0.5,
                      },
                    },
                  },
                  '& .MuiSwitch-track': {
                    backgroundColor: 'grey', // 未选中状态的轨道颜色（灰色）
                    opacity: 0.3,
                  },
                }}
              />
            </Box>
            <IconButton
              edge="end"
              sx={{ color: '#ffbb34' }}
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Box sx={{ height: '1px', backgroundColor: '#eeeeee' }} />
      </Box>

      <Box
        sx={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden',
          padding: '16px 0',  // 只保留上下 padding
        }}
      >
        {/* 问题 */}
        <BlinkingText
          text={segment.question}
          style={{
            fontSize: '20px',
            fontWeight: 'bold',
            color: '#ffbb34',
            marginBottom: '8px',
            padding: '0 16px',  // 左右都添加 16px 的内边距
          }}
          duration={1000}
          autoPlay={true}
          audioPlayer={audioPlayer.current}
          needSave={true}
          videoFileName={videoFilename}
        />

        {/* 对话内容 */}
        <Box
          ref={conversationBoxRef}
          sx={{
            flex: 1,
            overflowY: 'auto',
            padding: '8px 16px',  // 使用相同的左右内边距
            marginBottom: '8px',
            // 自定义滚动条样式
            '&::-webkit-scrollbar': {
              width: '6px',
              backgroundColor: 'transparent',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: 'rgba(0, 0, 0, 0.2)',
              borderRadius: '3px',
              '&:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.3)',
              },
            },
            '&::-webkit-scrollbar-track': {
              backgroundColor: 'transparent',
            },
          }}
        >
          {renderConversation()}
          <div ref={conversationEndRef} />
        </Box>

        {/* 录音按钮 */}
        <Box sx={{
          display: 'flex',
          justifyContent: 'center',
          padding: '8px',
          paddingRight: '16px',  // 为按钮区域添加右侧 padding
          flexShrink: 0,
        }}>
          <IconButton
            autoFocus
            sx={{
              backgroundColor: isRecording ? '#b885fe' : '#ffbb34',
              '&:hover': {
                backgroundColor: isRecording ? '#b885fe' : '#ffa726',
              },
            }}
            onClick={handleMicButtonClick}
          >
            {isRecording ? <StopIcon sx={{ color: 'white' }} /> : <MicIcon sx={{ color: 'white' }} />}
          </IconButton>
        </Box>
      </Box>
    </Dialog>
  );
}

export default QuestionInteraction;