import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import ReactPlayer from 'react-player';
import { GlobalData } from '../global_data';
import {
  Grid,
  Text,
  TextInput,
  Textarea,
  Select,
  Button,
  Card,
  LoadingOverlay,
  InputLabel,
  Group,
  Modal,
} from '@mantine/core';


import { Snackbar, Alert, Slider, Box, IconButton } from '@mui/material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';

// LessonPlanSegment 组件


function EditLessonPlanPage() {
  const { video_filename } = useParams(); // 获取视频文件名称
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [videoTitle, setVideoTitle] = useState('');
  const [lessonPlanSummary, setLessonPlanSummary] = useState('');
  const [lessonPlan, setLessonPlan] = useState([]);
  const [videoDuration, setVideoDuration] = useState(0);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const [regenerateModalOpen, setRegenerateModalOpen] = useState(false); // 控制确认框的显示
  const [deleteModalOpen, setDeleteModalOpen] = useState(false); // 控制删除确认框的显示

  //const [currentTab, setCurrentTab] = useState(0); // 当前选中的 Tab，0 - Summary, 1 - Easy, 2 - Medium, 3 - Hard

  const playerRef = useRef(null);
  const lessonPlanRef = useRef([]); // 使用 useRef 存储 segment 数据
  const titleRef = useRef(''); // 使用 useRef 存储 title
  const summaryRef = useRef(''); // 使用 useRef 存储 summary

  // 获取教案数据
  useEffect(() => {
    const fetchLessonPlan = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(
          `${GlobalData.serverURL}/lesson_plan/?video_filename=${video_filename}`
        );
        if (response.status === 200) {
          // setVideoTitle(response.data.title);
          // setLessonPlanSummary(response.data.summary);
          // setLessonPlan(response.data.segments);

          // 初始化 useRef
          titleRef.current = response.data.title;
          summaryRef.current = response.data.summary;
          lessonPlanRef.current = response.data.segments;

        } else {
          console.error('获取教案失败:', response.status);
          setSnackbarSeverity('error');
          setSnackbarMessage('获取教案失败');
          setShowSnackbar(true);
        }
      } catch (error) {
        console.error('获取教案时发生错误:', error);
        setSnackbarSeverity('error');
        setSnackbarMessage('获取教案失败');
        setShowSnackbar(true);
      } finally {
        setIsLoading(false);
      }
    };
    fetchLessonPlan();
  }, []);

  // 处理视频加载完成事件
  const handleVideoReady = (player) => {
    setVideoDuration(Math.floor(player.getDuration()));
  };

  // 重新生成教案（删除教案并跳转到首页，后端会自动重新生成）
  const handleRegenerateLessonPlan = async () => {
    setRegenerateModalOpen(false); // 关闭确认框
    setIsLoading(true);
    try {
      const response = await axios.delete(`${GlobalData.serverURL}/delete_lesson_plan/?video_filename=${video_filename}`);
      if (response.status === 200) {
        // 删除成功
        setSnackbarSeverity('success');
        setSnackbarMessage('教案已删除，将重新生成');
        setShowSnackbar(true);

        // 删除成功后跳转到首页
        navigate('/home');
      } else {
        // 删除失败
        console.error('教案删除失败:', response.status);
        setSnackbarSeverity('error');
        setSnackbarMessage('教案删除失败');
        setShowSnackbar(true);
      }
    } catch (error) {
      console.error('删除教案时发生错误:', error);
      setSnackbarSeverity('error');
      setSnackbarMessage('教案删除失败');
      setShowSnackbar(true);
    } finally {
      setIsLoading(false);
    }
  };

  // 添加删除视频及教案的处理函数
  const handleDeleteVideoAndLessonPlan = async () => {
    setDeleteModalOpen(false); // 关闭确认框
    setIsLoading(true);
    try {
      const response = await axios.delete(
        `${GlobalData.serverURL}/delete_video_and_lesson_plan/?video_filename=${video_filename}`
      );
      // 删除成功
      setSnackbarSeverity('success');
      setSnackbarMessage('视频及教案已删除');
      setShowSnackbar(true);

      // 删除成功后跳转到首页
      navigate('/home');
    } catch (error) {
      // 修改错误处理逻辑
      console.error('删除视频及教案时发生错误:', error);
      setSnackbarSeverity('error');
      // 如果后端返回了具体的错误信息，则显示该信息
      const errorMessage = error.response?.data?.detail || '视频及教案删除失败';
      setSnackbarMessage(errorMessage);
      setShowSnackbar(true);
    } finally {
      setIsLoading(false);
    }
  };

  // 更新 lessonPlan (只在保存按钮点击时调用)
  const handleSaveLessonPlan = async () => {
    // setLessonPlan(lessonPlanRef.current); // 从 useRef 中获取所有 segment 数据
    // setVideoTitle(titleRef.current); // 从 titleRef 中获取 title
    // setLessonPlanSummary(summaryRef.current); // 从 summaryRef 中获取 summary

    // 保存 lessonPlan 到后端
    setIsLoading(true);

    try {
      const response = await axios.put(`${GlobalData.serverURL}/update_lesson_plan/`, {
        video_filename: video_filename,
        title: titleRef.current,
        summary: summaryRef.current,
        segments: lessonPlanRef.current,
        unique_id: localStorage.getItem('unique_id')
      });
      console.log(
        'videoTitle:', videoTitle, titleRef.current,
        'lessonPlanSummary:', lessonPlanSummary,
        'lessonPlan:', lessonPlan, 'unique_id:',
        localStorage.getItem('unique_id'),
      );

      if (response.status === 200) {
        // 保存成功
        setSnackbarSeverity('success');
        setSnackbarMessage('教案保存成功');
        setShowSnackbar(true);

        // 保存成功后跳转到首页
        navigate('/home');
      } else {
        // 保存失败
        console.error('教案保存失败:', response.status);
        setSnackbarSeverity('error');
        setSnackbarMessage('教案保存失败');
        setShowSnackbar(true);
      }

    } catch (error) {
      console.error('保存教案时发生错误:', error);
      setSnackbarSeverity('error');
      setSnackbarMessage('教案保存失败');
      setShowSnackbar(true);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div style={{ padding: '10px' }}>
      <LoadingOverlay visible={isLoading} />
      <Snackbar
        opened={showSnackbar}
        onClose={() => setShowSnackbar(false)}
        withCloseButton
        severity={snackbarSeverity}
        mx="auto"
      >
        <Alert severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>

      <Grid gutter="xl">
        {/* 左侧教案区域 */}
        <Grid.Col span={8} p="md">
          {/* 将标题和按钮放在同一行 */}
          <Group position="apart" mb="md"> {/* 使用 Group 组件 */}
            <Text size="xl" weight={700}>
              Edit Lesson Plan
            </Text>
            <Group>
              <Button
                onClick={() => setRegenerateModalOpen(true)}
              >
                重新生成教案
              </Button>
              <Button
                color="red"
                onClick={() => setDeleteModalOpen(true)}
              >
                删除视频及教案
              </Button>
            </Group>
          </Group>

          {/* 缩略图 */}
          <Thumbnail
            videoFilename={video_filename}
            playerRef={playerRef} // 传递 playerRef
            onThumbnailCaptured={(newThumbnailUrl) => {
              // 在这里处理缩略图更新后的逻辑
            }}
          />


          {/* 标题 */}
          <EditableTitle titleRef={titleRef} />

          {/* 教案摘要 */}
          <EditableSummary summaryRef={summaryRef} />

          {/* 教案片段 */}
          {lessonPlanRef.current.length === 0 ? (
            <Text>暂无教案片段</Text>
          ) : (
            lessonPlanRef.current.map((segment, index) => (
              <LessonPlanSegment
                key={index}
                segment={segment}
                lessonPlanRef={lessonPlanRef} // 将 lessonPlanRef 传递给子组件
                setLessonPlan={setLessonPlan} // 传递 setLessonPlan 函数
                videoDuration={videoDuration} // 传递视频总时长
                playerRef={playerRef}
              />
            ))
          )}

          {/* 保存按钮 */}
          <Button
            variant="filled"
            color="blue"
            fullWidth
            mt="md"
            onClick={handleSaveLessonPlan}
          >
            保存
          </Button>
        </Grid.Col>

        {/* 右侧视频区域 */}
        <Grid.Col span={4}>
          {/* 视频播放器 */}
          <Box sx={{
            position: 'sticky', top: 10,  // 固定在顶部
          }}
          >
            <ReactPlayer
              ref={playerRef}
              url={`${GlobalData.serverURL}/getvideofile/${video_filename}`}
              controls
              width="100%"
              height="auto"
              pip={false}
              onReady={handleVideoReady}
            // config={{
            //   file: {
            //     attributes: {
            //       controlsList: "nofullscreen",
            //     },
            //   },
            // }}
            />
          </Box>
        </Grid.Col>
      </Grid>
      {/* 重新生成确认框 */}
      <Modal
        opened={regenerateModalOpen}
        onClose={() => setRegenerateModalOpen(false)}
        title="确认重新生成教案"
        centered
      >
        <Text>确定要删除当前教案并重新生成吗？</Text>
        <Group position="right" mt="md">
          <Button variant="default" onClick={() => setRegenerateModalOpen(false)}>
            取消
          </Button>
          <Button color="red" onClick={handleRegenerateLessonPlan}>
            确认
          </Button>
        </Group>
      </Modal>
      {/* 删除确认框 */}
      <Modal
        opened={deleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        title="确认删除视频及教案"
        centered
      >
        <Text>确定要删除当前视频及其教案吗？此操作不可恢复。</Text>
        <Group position="right" mt="md">
          <Button variant="default" onClick={() => setDeleteModalOpen(false)}>
            取消
          </Button>
          <Button color="red" onClick={handleDeleteVideoAndLessonPlan}>
            确认删除
          </Button>
        </Group>
      </Modal>
    </div >
  );
}


// Thumbnail组件
const Thumbnail = ({ videoFilename, playerRef, onThumbnailCaptured }) => { // 添加 playerRef 参数
  const [thumbnailUrl, setThumbnailUrl] = useState('');
  const [needRefresh, setNeedRefresh] = useState(false); // 添加 needRefresh 和 setNeedRefresh 状态变量

  useEffect(() => {
    // const fetchThumbnail = async () => {
    //   try {
    //     const response = await axios.get(
    //       `${GlobalData.serverURL}/lesson_plan/?video_filename=${videoFilename}`
    //     );
    //     if (response.status === 200) {
    //       setThumbnailUrl(response.data.thumbnail_url); // 设置缩略图 URL
    //     } else {
    //       console.error('获取缩略图失败:', response.status);
    //     }
    //   } catch (error) {
    //     console.error('获取缩略图时发生错误:', error);
    //   }
    // };
    // fetchThumbnail();

    setThumbnailUrl(`${GlobalData.serverURL}/thumbnails/${videoFilename.replace('.mp4', '')}.jpg?t=${Date.now()}`); // 初始化缩略图 URL
    setNeedRefresh(false);
  }, [needRefresh]);

  console.log('thumbnailUrl:', thumbnailUrl);

  const handleCaptureThumbnail = async () => {
    try {
      const currentTime = playerRef.current.getCurrentTime(); // 获取当前视频播放时间
      console.log('当前播放时间:', currentTime);
      const response = await axios.post(
        `${GlobalData.serverURL}/update_thumbnail/`,
        {
          video_filename: videoFilename,
          time: currentTime,
        }
      );
      if (response.status === 200) {
        setNeedRefresh(true); // 标记需要刷新
        onThumbnailCaptured(response.data.thumbnail_url); // 通知父组件缩略图已更新
      } else {
        console.error('��取缩略图失败:', response.status);
      }
    } catch (error) {
      console.error('截取缩略图时发生错误:', error);
    }
  };

  return (
    <Card shadow="none" p="md" mb="sm">
      <Card.Section>
        <img src={thumbnailUrl} alt="Video Thumbnail" width="50%" />

      </Card.Section>
      <Card.Section>
        <Button onClick={handleCaptureThumbnail} >使用当前帧</Button>

      </Card.Section>

    </Card>
  );
};


// EditableTitle 组件
const EditableTitle = ({ titleRef }) => {
  const [title, setTitle] = useState('');

  useEffect(() => {
    // 监听 titleRef.current 的变化
    setTitle(titleRef.current);
  }, [titleRef.current]);

  const handleChange = (event) => {
    setTitle(event.currentTarget.value);
    titleRef.current = event.currentTarget.value;
  };

  return (
    <TextInput
      label="Title"
      placeholder="请输入标题"
      value={title}
      onChange={handleChange}
      mb="md"
    />
  );
};

// EditableSummary 组件
const EditableSummary = ({ summaryRef }) => {
  const [summary, setSummary] = useState('');

  useEffect(() => {
    // 监听 summaryRef.current 的变化
    setSummary(summaryRef.current);
  }, [summaryRef.current]);

  const handleChange = (event) => {
    setSummary(event.currentTarget.value);
    summaryRef.current = event.currentTarget.value;
  };

  return (
    <Textarea
      label="Summary"
      placeholder="请输入摘要"
      value={summary}
      onChange={handleChange}
      autosize
      minRows={3}
      mb="md"
    />
  );
};

const LessonPlanSegment = ({ segment, lessonPlanRef, setLessonPlan, videoDuration, playerRef }) => {
  const [startTime, setStartTime] = useState(segment.startTime);
  const [endTime, setEndTime] = useState(segment.endTime);
  const [description, setDescription] = useState(segment.description);
  const [question, setQuestion] = useState(segment.question);
  const [answer, setAnswer] = useState(segment.answer);
  const [difficulty, setDifficulty] = useState(segment.difficulty);
  //console.log('startTime:', startTime, 'endTime:', endTime);
  //console.log('segment：', segment, 'lessonPlanRef：', lessonPlanRef.current);

  // 更新 segment 数据 (直接更新 lessonPlanRef 中的数据)
  const handleSegmentUpdate = (field, value) => {
    switch (field) {
      case 'startTime':
        setStartTime(value);
        break;
      case 'endTime':
        setEndTime(value);
        break;
      case 'description':
        setDescription(value);
        break;
      case 'question':
        setQuestion(value);
        break;
      case 'answer':
        setAnswer(value);
        break;
      case 'difficulty':
        setDifficulty(value);
        break;
      default:
        break;
    }

    // 更新 lessonPlanRef 中的数据
    lessonPlanRef.current = lessonPlanRef.current.map((s) =>
      s.no === segment.no ? { ...s, [field]: value } : s
    );
  };

  // 将秒数转换为时间字符串 (MM:SS)
  const formatSecondsToTimeString = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes.toString().padStart(2, '0')}:${remainingSeconds
      .toString()
      .padStart(2, '0')}`;
  };

  // 将时间字符串 (MM:SS) 转换为秒数
  const parseTimeStringToSeconds = (timeString) => {
    const [minutes, seconds] = timeString.split(':').map(Number);
    return minutes * 60 + seconds;
  };

  // 计算 RangeSlider 的 min/max 值
  const sliderMin = useMemo(() => {
    const previousSegment = lessonPlanRef.current.find(
      (s) => s.no === segment.no - 1 && s.difficulty === segment.difficulty // 只考虑相同难度的片段
    );
    return previousSegment
      ? parseTimeStringToSeconds(previousSegment.endTime) + 1
      : 0;
  }, [segment.no, segment.difficulty, lessonPlanRef.current]); // 添加 segment.difficulty 到依赖数组

  const sliderMax = useMemo(() => {
    const nextSegment = lessonPlanRef.current.find(
      (s) => s.no === segment.no + 1 && s.difficulty === segment.difficulty // 只考虑相同难度的片段
    );
    return nextSegment
      ? parseTimeStringToSeconds(nextSegment.startTime) - 1
      : videoDuration;
  }, [segment.no, segment.difficulty, lessonPlanRef.current, videoDuration]); // 添加 segment.difficulty 到依赖数组

  // 创建 Slider 的标记
  const marks = useMemo(() => [
    {
      value: sliderMin,
      label: formatSecondsToTimeString(sliderMin),
    },
    {
      value: parseTimeStringToSeconds(startTime), // 添加初始的 startTime
      label: startTime,
    },
    {
      value: parseTimeStringToSeconds(endTime), // 添加初始的 endTime
      label: endTime,
    },
    {
      value: sliderMax,
      label: formatSecondsToTimeString(sliderMax),
    },
  ], [sliderMin, sliderMax]); // 当 sliderMin 或 sliderMax 变化时重新计算



  // 处理 RangeSlider 变化 (实时更新)
  const handleSliderChange = (event, newValue) => { // 注意这里的 newValue 是一个数组
    const newStartTime = formatSecondsToTimeString(newValue[0]);
    const newEndTime = formatSecondsToTimeString(newValue[1]);

    // 更新当前片段的 startTime 和 endTime 状态
    setStartTime(newStartTime);
    setEndTime(newEndTime);

    // 判断是哪个滑块发生了变化
    const isStartTimeChanged = newValue[0] !== parseTimeStringToSeconds(segment.startTime);

    // 跳转视频
    if (playerRef.current) {
      if (isStartTimeChanged) {
        playerRef.current.seekTo(newValue[0], 'seconds');
      } else {
        playerRef.current.seekTo(newValue[1], 'seconds');
      }
    }
  };

  // 处理 RangeSlider 变化（调整结束后的处理）
  // 处理 Slider 变化（调整结束后的处理）
  const handleSliderChangeEnd = (event, newValue) => { // 注意参数
    const newStartTime = formatSecondsToTimeString(newValue[0]);
    const newEndTime = formatSecondsToTimeString(newValue[1]);

    // 更新当前片段的 startTime 和 endTime
    handleSegmentUpdate('startTime', newStartTime);
    handleSegmentUpdate('endTime', newEndTime);

    //触发组件重新渲染(更新相邻片段的 min/max 值)
    setLessonPlan([...lessonPlanRef.current]);
  };

  //创建自定义 Label 组件(带有播放按钮)
  const TimeLabelWithPlay = ({ labelText, timeValue, playerRef }) => {
    const parseTimeStringToSeconds = (timeString) => {
      const [minutes, seconds] = timeString.split(':').map(Number);
      return minutes * 60 + seconds;
    };

    return (
      <InputLabel
        sx={{
          display: 'flex',
          alignItems: 'center',
          cursor: 'pointer',
        }}
        onClick={() => playerRef.current.seekTo(parseTimeStringToSeconds(timeValue), 'seconds')}
      >
        {/* 使用 InputLabel 包裹 labelText */}
        {labelText}
        <IconButton
          sx={{ marginLeft: '4px' }}
          onClick={() => playerRef.current.seekTo(parseTimeStringToSeconds(timeValue), 'seconds')}
        >
          <PlayCircleIcon />
        </IconButton>
      </InputLabel>
    );
  };

  return (
    <Card withBorder shadow="sm" p="md" mb="sm">
      <Text size="sm" mb="xs" fw={500}>
        Segment {segment.no}
      </Text>
      {/* 只读的开始和结束时间显示 */}
      <Grid gutter="xs" mt="sm">
        <Grid.Col span={6}>
          <TextInput label={<TimeLabelWithPlay labelText="Start Time" timeValue={startTime} playerRef={playerRef} />} value={startTime} readOnly />
        </Grid.Col>
        {/* 播放按钮 */}
        <Grid.Col span={6}>
          <TextInput label={<TimeLabelWithPlay labelText="End Time" timeValue={endTime} playerRef={playerRef} />} value={endTime} readOnly />
        </Grid.Col>
        {/* 播放按钮 */}

      </Grid>

      {/* 时间选择器 */}
      <Box style={{ display: 'flex', justifyContent: 'center', marginTop: 16 }}>
        <Slider
          sx={{
            '& .MuiSlider-markLabel': { fontSize: '12px', color: 'grey' }, // 修改 mark label 的字体大小
            width: '95%'
          }} // 设置 Slider 宽度为 80%
          min={sliderMin}
          max={sliderMax}
          value={[
            parseTimeStringToSeconds(startTime),
            parseTimeStringToSeconds(endTime),
          ]}
          onChange={handleSliderChange}
          onChangeCommitted={handleSliderChangeEnd} // 使用 onChangeCommitted 事件
          valueLabelDisplay="auto" // 自动显示值标签
          valueLabelFormat={formatSecondsToTimeString} // 使用 formatTime 函数格式化值标签
          marks={marks} // 添加 marks 属性

        />
      </Box>
      <Textarea
        label="Description"
        placeholder="Input description"
        value={description}
        onChange={(event) =>
          handleSegmentUpdate('description', event.currentTarget.value)
        }
        autosize
        minRows={2}
      />
      <Textarea
        label="Question"
        placeholder="Input question"
        value={question}
        onChange={(event) =>
          handleSegmentUpdate('question', event.currentTarget.value)
        }
        autosize
        minRows={2}
      />
      <Textarea
        label="Answer"
        placeholder="Input answer"
        value={answer}
        onChange={(event) =>
          handleSegmentUpdate('answer', event.currentTarget.value)
        }
        autosize
        minRows={2}
      />
      <Select
        label="Difficulty"
        placeholder="Select difficulty"
        value={difficulty.toString()}
        onChange={(value) =>
          handleSegmentUpdate('difficulty', parseInt(value, 10))
        }
        data={[
          { value: '0', label: 'Easy' },
          { value: '1', label: 'Medium' },
          { value: '2', label: 'Hard' },
        ]}
        readOnly
      />
    </Card>
  );
};

export default EditLessonPlanPage;