import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { GlobalData } from '../global_data';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import { IconButton } from '@mui/material';


const BlinkingText = ({ text, style, duration = 2000, autoPlay = false, audioPlayer, needSave = true, videoFileName }) => {

  const [audioUrl, setAudioUrl] = useState(null); // 用于存储音频文件的 URL

  const [displayedText, setDisplayedText] = useState('');
  const timerRef = useRef(null);
  const [showIcon, setShowIcon] = useState(false); // 新增状态用于控制图标显示

  // useEffect(() => {
  //   const blink = () => {
  //     setOpacity(prevOpacity => 1 - prevOpacity); // 切换透明度
  //   };

  //   const intervalId = setInterval(blink, duration);

  //   return () => clearInterval(intervalId); // 清理定时器
  // }, [duration]);

  useEffect(() => {
    if (autoPlay) {
      startTyping();
      fetchAudio();
    }

    // 清理定时器
    return () => {
      clearTimeout(timerRef.current);
      if (audioUrl) {
        URL.revokeObjectURL(audioUrl);
      }
    }
  }, [autoPlay]);


  const startTyping = () => {
    setShowIcon(false); // 开始打字后，设置 showIcon 为 false 隐藏重播图标
    let index = 0;
    timerRef.current = setInterval(() => {
      if (index < text.length) {
        setDisplayedText(text.substring(0, index + 1));
        index++;
      } else {
        clearInterval(timerRef.current);
        setShowIcon(true); // 文本打完字后，设置 showIcon 为 true 显示重播图标
      }
    }, duration / text.length);
  };

  const fetchAudio = async () => {
    try {
      const response = await axios.post(`${GlobalData.serverURL}/get_audio/`, {
        text,
        video_filename: videoFileName,
        need_save: needSave,
      },
        { responseType: 'blob' });

      if (response.status === 200) {
        const blob = new Blob([response.data], { type: 'audio/mpeg' });
        const url = URL.createObjectURL(blob);
        setAudioUrl(url);
        if (autoPlay && audioPlayer) {
          // 这里使用 audioPlayer.current.play() 播放音频
          audioPlayer.src = url;
          audioPlayer.play().catch(error => {
            console.error("音频播放错误", error); // 添加错误处理
          });
        }
      } else {
        console.error("获取音频失败:", response.status);
      }
    } catch (error) {
      console.error("获取音频失败:", error);
    }
  };


  const handleClick = () => {
    startTyping();
    if (audioUrl && audioPlayer) {
      audioPlayer.src = audioUrl;
      audioPlayer.play();

    }
  };


  return (
    <>
      <span style={{ ...style, cursor: 'pointer' }} onClick={handleClick}>
        {displayedText}
        {showIcon && ( // 只有当 showIcon 为 true 时，才显示图标
          <IconButton
            aria-label="replay"
          >
            <VolumeUpIcon style={{ color: '#ffbb34' }} />
          </IconButton>
        )}
      </span>


    </>

  );
};

export default BlinkingText;