import React, { useState, useEffect, useRef, useContext } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import axios from './axiosInstance';
import { useNavigate } from 'react-router-dom';
import { GlobalData } from '../global_data';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';



function LoginPage() {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [error, setError] = useState('');
  const [countdown, setCountdown] = useState(60);
  const [sendVeriBottonStatus, setSendVeriBottonStatus] = useState(0); // 0-不可点击，1-可点击，2-倒计时
  const navigate = useNavigate();
  const timerRef = useRef(null); // 使用 useRef 存储定时器

  const [showVerificationCodeDialog, setShowVerificationCodeDialog] = useState(false); // 新增状态，控制弹窗显示
  const [receivedVerificationCode, setReceivedVerificationCode] = useState('');      // 新增状态，存储接收到的验证码


  useEffect(() => {
    // 清理定时器
    return () => clearTimeout(timerRef.current);
  }, []);


  const sendVerificationCode = async () => {
    if (!/^\d{11}$/.test(phoneNumber)) {
      setError('请输入11位数字');
      return;
    }
    setSendVeriBottonStatus(2);
    setCountdown(60);
    console.log('Sending verification code...');
    try {
      const response = await axios.post(`${GlobalData.serverURL}/send_verification_code/`, {
        phone: phoneNumber
      });

      if (response.status === 200) {
        setReceivedVerificationCode(response.data.verification_code);
        setShowVerificationCodeDialog(true);


        // 验证码发送成功，启动倒计时
        timerRef.current = setInterval(() => {
          setCountdown((prevCountdown) => {
            if (prevCountdown > 1) {
              return prevCountdown - 1;
            } else {
              clearInterval(timerRef.current);
              setSendVeriBottonStatus(1);
              return 0;
            }
          });
        }, 1000);



      } else {
        setError('验证码发送失败');
        setSendVeriBottonStatus(1);
        console.error('Failed to send verification code:', response.data);
      }

    } catch (err) {
      setError('验证码发送失败');
      setSendVeriBottonStatus(1);
      console.error('Error sending verification code:', err);
    }
  };




  const handleSubmit = async (event) => {
    event.preventDefault();
    setError(''); // 清空之前的错误信息

    if (!/^\d{6}$/.test(verificationCode)) {
      setError('请输入6位验证码');
      return;
    }

    try {
      const response = await axios.post(`${GlobalData.serverURL}/login_verify/`, {
        phone: phoneNumber,
        verification_code: verificationCode,
      });

      if (response.status === 200 && response.data.message === "OK") {
        // 登录成功
        console.log('Login successful:', response.data);
        localStorage.setItem('unique_id', response.data.user.unique_id);

        // 存储 Access Token 和 Refresh Token
        localStorage.setItem('access_token', response.data.access_token);
        localStorage.setItem('refresh_token', response.data.refresh_token);
        navigate('/home');

      } else {
        // 登录失败，根据后端返回信息设置错误信息
        setError(response.data.detail || "登录失败，请检查手机号或验证码");
        console.error('Login failed:', response.data);

      }
    } catch (err) {
      // 处理其他错误
      setError("登录失败，请稍后再试");
      console.error('Login error:', err);

    }
  };

  const handlePhoneNumberChange = (event) => {

    setPhoneNumber(event.target.value);
    // 控制发送验证码按钮状态
    if (/^1\d{10}$/.test(event.target.value) && sendVeriBottonStatus !== 2) {
      setSendVeriBottonStatus(1);
    } else if (sendVeriBottonStatus !== 2) {
      setSendVeriBottonStatus(0);
    }


  };



  return (

    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '100vh', // 使内容垂直居中
        padding: '32px', // 添加 padding
        background: 'linear-gradient(to bottom, #FFFFFF, #FFFFFF)', // 模拟渐变背景
      }}
    >
      {/* Logo -  用 img 标签代替 SvgPicture.network */}
      <img
        src={`${GlobalData.serverURL}/icon/toptalk_logo_nobg.svg`}
        alt="TopTalk Logo"
        width="150"
        height="150"
      />

      <Typography variant="h6" component="h6" gutterBottom sx={{ color: '#ffbb34' }}>
        登录/注册
      </Typography>

      {error && <Typography variant="body1" color="error" gutterBottom>{error}</Typography>}

      <form onSubmit={handleSubmit}>

        <TextField
          label="手机号"
          variant="outlined"
          margin="normal"
          fullWidth
          value={phoneNumber}
          onChange={handlePhoneNumberChange}
          sx={{
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: 'lightgray', // 默认边框颜色
              },
              '&:hover fieldset': {
                borderColor: '#ffbb34', // hover 时的边框颜色
              },
              '&.Mui-focused fieldset': {
                borderColor: '#ffbb34', // 获得焦点时的边框颜色
              },
            },
          }}
        />

        <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '16px' }}>
          <TextField
            label="验证码"
            variant="outlined"
            margin="normal"
            fullWidth // 让验证码输入框占据剩余宽度
            value={verificationCode}
            onChange={(e) => setVerificationCode(e.target.value)}
            sx={{
              marginRight: '16px', // 添加右边距
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: 'lightgray', // 默认边框颜色
                },
                '&:hover fieldset': {
                  borderColor: '#ffbb34', // hover 时的边框颜色
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#ffbb34', // 获得焦点时的边框颜色
                },
              },
            }}
          />

          <Button
            variant="contained"
            color={sendVeriBottonStatus === 1 ? 'primary' : 'disabled'} // 根据状态设置颜色
            disabled={sendVeriBottonStatus !== 1} // 根据状态设置 disabled 属性
            onClick={sendVerificationCode}
            sx={{
              minWidth: '120px', // 设置最小宽度
              backgroundColor: sendVeriBottonStatus === 1 ? '#ffbb34' : 'lightgray', // 根据状态设置背景颜色

              '&:hover': {
                backgroundColor: sendVeriBottonStatus === 1 ? '#ffa726' : 'lightgray', // hover 时的背景颜色
              },

            }}
          >
            {sendVeriBottonStatus === 2 ? `${countdown} 秒后重试` : '获取验证码'}
          </Button>
        </Box>

        <Button
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
          disabled={verificationCode.length !== 6 || phoneNumber.length !== 11} // 根据输入框内容禁用按钮
          sx={{
            marginTop: '32px', // 调整 marginTop
            backgroundColor: (verificationCode.length === 6 && phoneNumber.length === 11) ? '#ffbb34' : 'lightgray', // 根据状态设置背景颜色
            '&:hover': {
              backgroundColor: (verificationCode.length === 6 && phoneNumber.length === 11) ? '#ffa726' : 'lightgray', // hover 时的背景颜色
            },
          }}
        >
          登录
        </Button>
      </form>
      {/* 新增：验证码弹窗 */}
      {/* <Dialog open={showVerificationCodeDialog} onClose={() => setShowVerificationCodeDialog(false)}>
        <DialogTitle>验证码</DialogTitle>
        <DialogContent>
          <DialogContentText>
            短信验证尚未开通，您可以直接输入验证码：{receivedVerificationCode}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowVerificationCodeDialog(false)} color="primary">
            关闭
          </Button>
        </DialogActions>
      </Dialog> */}
    </Box >

  );
}

export default LoginPage;