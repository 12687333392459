import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { AppBar, Toolbar, Typography, Box, Tabs, Tab, IconButton, Dialog, CircularProgress, useMediaQuery } from '@mui/material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { GlobalData } from '../global_data';
import ReactPlayer from 'react-player'; // 使用 react-player
import QuestionInteraction from './QuestionInteraction'; // 引入问题交互界面组件
import MyAppBar from './MyAppBar'; // 引入自定义 AppBar
import StudyRecordManager from './StudyRecordManager';
import PointsAnimation from './PointsAnimation';

function PlayPage() {
  const { stored_filename } = useParams();
  const [lessonPlan, setLessonPlan] = useState(null);
  const playerRef = useRef(null)
  const [isPlaying, setIsPlaying] = useState(true); // 播放状态
  const [currentTime, setCurrentTime] = useState(0);
  const currentSegment = useRef(null);// 当前播放的片段
  const lastSegment = useRef(null);// 上一个播放的片段
  const [tabValue, setTabValue] = useState(1); // 默认选中 Easy 标签
  const [isQuestionDialogOpen, setIsQuestionDialogOpen] = useState(false);
  const isSmallScreen = useMediaQuery('(max-width:900px)'); // 定义屏幕大小断点
  const [videoOrientation, setVideoOrientation] = useState('landscape'); // 默认横向
  const [isWindowActive, setIsWindowActive] = useState(true);
  const [handleQuestionAnswered, setHandleQuestionAnswered] = useState(null);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [showPointsAnimation, setShowPointsAnimation] = useState(false);

  const previousFullScreenState = useRef(false); // 用于存储对话框弹出前的全屏状态


  const videoUrl = `${GlobalData.serverURL}/getvideofile/${stored_filename}`;

  //获取LessonPlan
  useEffect(() => {
    const fetchLessonPlan = async () => {
      try {
        const response = await axios.get(`${GlobalData.serverURL}/lesson_plan/?video_filename=${stored_filename}`);
        if (response.status === 200) {
          setLessonPlan(response.data);
        } else {
          console.error('Failed to fetch lesson plan:', response.status);
        }
      } catch (error) {
        console.error('Error fetching lesson plan:', error);
      }
    };

    fetchLessonPlan();
  }, [stored_filename]);


  // 设置视频方向
  useEffect(() => {
    if (playerRef.current) {

      const handleReady = () => {
        const aspectRatio = playerRef.current.getInternalPlayer()?.getAspectRatio() ?? 16 / 9;
        setVideoOrientation(aspectRatio > 1 ? 'landscape' : 'portrait');
      };

      playerRef.current.getInternalPlayer()?.addEventListener('ready', handleReady);
      console.log('videoOrientation:', videoOrientation);

      return () => {
        // 组件卸载时移除监听器
        //playerRef.current.getInternalPlayer()?.removeEventListener('ready', handleReady);
      };

    }

  }, [playerRef]);



  //播放过程中判断是否需要打开问题对话框
  const handleProgress = (state) => {
    setCurrentTime(state.playedSeconds);
    checkSegmentPlayback(state.playedSeconds);
  };



  //检查当前播放时间是否在片段时间范围内
  const checkSegmentPlayback = (playedSeconds) => {
    if (!lessonPlan) return;

    const difficulty = tabValue === 0 ? null : tabValue - 1; // Summary 标签页不处理
    const segments = lessonPlan.segments.filter(segment => difficulty === null ? false : segment.difficulty === difficulty);

    const newSegment = segments.find(segment =>
      playedSeconds >= parseTimeStringToSeconds(segment.startTime) &&
      playedSeconds <= parseTimeStringToSeconds(segment.endTime)
    );

    if (newSegment !== currentSegment.current) {

      lastSegment.current = currentSegment.current;
      currentSegment.current = newSegment;

      if (!newSegment && lastSegment.current && lastSegment.current.difficulty === difficulty) {
        //console.log('问题Segment播放完毕，打开问题对话框');
        //videoPlayer.seekTo(parseTimeStringToSeconds(lastSegment.startTime));//将视频跳转到问题片段开始的地方
        setIsPlaying(false); // 暂停播放

        // 先保存全屏状态，再退出全屏
        previousFullScreenState.current = document.fullscreenElement !== null;
        //console.log('弹出问题对话框前：previousFullScreenState:', previousFullScreenState.current);
        if (document.fullscreenElement !== null) {
          document.exitFullscreen();
        }

        setIsQuestionDialogOpen(true);

      }
    }
  };

  const parseTimeStringToSeconds = (timeString) => {
    const [minutes, seconds] = timeString.split(':').map(Number);
    return minutes * 60 + seconds;
  };

  const handleCloseQuestionDialog = () => {
    setIsQuestionDialogOpen(false);
    setIsPlaying(true); // 关闭对话框后继续播放
    // 根据之前的全屏状态决定是否进入全屏
    //console.log('关闭问题对话框后：previousFullScreenState:', previousFullScreenState.current);
    if (previousFullScreenState.current) {
      playerRef.current.getInternalPlayer().requestFullscreen();
    }
  };



  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    currentSegment.current = null; // 清空当前片段
    lastSegment.current = null; // 清空上一个片段
    // 修改：使用ReactPlayer的seekTo方法
    if (playerRef.current) {
      playerRef.current.seekTo(0, 'seconds');
    }
  };


  const formatTime = (timeInSeconds) => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = Math.floor(timeInSeconds % 60);
    return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
  };

  const renderTabs = () => (
    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
      <Tabs value={tabValue} onChange={handleTabChange} aria-label="basic tabs example">
        <Tab label="Summary" value={0} />
        <Tab label="Easy" value={1} />
        <Tab label="Medium" value={2} />
        <Tab label="Hard" value={3} />
      </Tabs>
    </Box>
  );

  const renderTabContent = () => {
    if (!lessonPlan) {
      return <CircularProgress />;
    }

    const difficulty = tabValue === 0 ? null : tabValue - 1;
    const segments = lessonPlan.segments.filter(segment => difficulty === null ? false : segment.difficulty === difficulty);

    switch (tabValue) {
      case 0:
        return (
          <Box sx={{ p: 1 }}>
            <Typography variant="body1" whiteSpace="pre-line">{lessonPlan.summary}</Typography>
          </Box>
        );
      default:
        return (
          <Box sx={{ p: 1, minWidth: 370 }}>
            {segments.map((segment, index) => (
              <Box
                key={index}
                sx={{
                  mb: 1,
                  p: 1,
                  border: '1px solid #ccc',
                  borderRadius: 1,
                  backgroundColor: segment === currentSegment.current ? '#ffbb34' : 'transparent',//点击后的背景颜色
                  //boxShadow: segment === currentSegment.current ? `0 0 0 2px ${'red'}` : 'none', // 使用模板字符串
                  cursor: 'pointer',
                  "&:hover": {

                    backgroundColor: segment === currentSegment.current ? `0 0 0 2px ${'red'}` : '#f0f0f0',
                  }
                }}

                onClick={() => {
                  playerRef.current.seekTo(parseTimeStringToSeconds(segment.startTime), 'seconds');

                }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <IconButton onClick={() => playerRef.current.seekTo(parseTimeStringToSeconds(segment.startTime), 'seconds')}>
                    <PlayArrowIcon />
                  </IconButton>
                  <Typography variant="subtitle2" sx={{ width: 50, flexShrink: 0 }}>
                    {formatTime(parseTimeStringToSeconds(segment.startTime))} - {formatTime(parseTimeStringToSeconds(segment.endTime))}
                  </Typography>
                  <Typography variant="body2">{segment.question}</Typography>

                </Box>
              </Box>
            ))}
          </Box>
        );
    }
  };

  useEffect(() => {
    const handleVisibilityChange = () => {
      setIsWindowActive(!document.hidden);
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  useEffect(() => {
    const handleFullscreenChange = () => {
      const isFullscreenNow = document.fullscreenElement !== null;
      setIsFullscreen(isFullscreenNow);

    };

    document.addEventListener('fullscreenchange', handleFullscreenChange);

    // 同时监听 webkit 和 moz 前缀的事件，以支持更多浏览器
    document.addEventListener('webkitfullscreenchange', handleFullscreenChange);
    document.addEventListener('mozfullscreenchange', handleFullscreenChange);

    return () => {
      document.removeEventListener('fullscreenchange', handleFullscreenChange);
      document.removeEventListener('webkitfullscreenchange', handleFullscreenChange);
      document.removeEventListener('mozfullscreenchange', handleFullscreenChange);
    };
  }, []);

  const handlePlay = useCallback(() => {
    console.log('视频开始播放');
    setIsPlaying(true);
  }, []);

  const handlePause = useCallback(() => {
    console.log('视频暂停');
    setIsPlaying(false);
  }, []);

  const handleQuestionAnsweredCallback = useCallback((data) => {
    console.log('QuestionInteraction 触发问答记录:', data);
    if (!data || !data.question) {
      console.warn('无效的问答数据');
      return;
    }

    if (handleQuestionAnswered) {
      handleQuestionAnswered(data);
    } else {
      console.warn('handleQuestionAnswered 未定义');
    }
  }, [handleQuestionAnswered]);

  const setHandleQuestionAnsweredCallback = useCallback((handler) => {
    if (typeof handler === 'function') {
      console.log('StudyRecordManager 设置问答处理函数');
      setHandleQuestionAnswered(() => handler);
    }
  }, []);

  const handlePointsEarned = useCallback(() => {
    console.log('PlayPage: handlePointsEarned 被调用');
    if (!showPointsAnimation) { // 只在动画未显示时触发
      console.log('当前 showPointsAnimation 状态:', showPointsAnimation);
      setShowPointsAnimation(true);
      console.log('设置 showPointsAnimation 为 true');
    }
  }, [showPointsAnimation]);

  return (
    <div>
      {/* 使用 MyAppBar 组件 */}
      <MyAppBar title={lessonPlan?.title || '加载中...'} showSearch={false} showAdd={false} showTabs={false} />

      <Box sx={{ position: 'relative' }}>
        {showPointsAnimation && (
          <PointsAnimation
            points={10}
            onAnimationEnd={() => {
              console.log('动画结束，设置 showPointsAnimation 为 false');
              setShowPointsAnimation(false);
            }}
          />
        )}

        <StudyRecordManager
          videoFilename={stored_filename}
          isPlaying={isPlaying}
          isWindowActive={isWindowActive}
          isFullscreen={isFullscreen}
          onQuestionAnswered={setHandleQuestionAnsweredCallback}
          onPointsEarned={handlePointsEarned}
        />
      </Box>

      <Box sx={{ display: 'flex', flexDirection: isSmallScreen ? 'column' : 'row', height: 'calc(100vh - 64px)' }}>
        <Box sx={{ width: isSmallScreen ? '100%' : '70%', p: isSmallScreen ? 0 : 1 }}> {/* 根据屏幕大小设置宽度 */}
          <ReactPlayer
            ref={playerRef}
            url={videoUrl}
            controls
            width="100%"
            height="auto" // 设置高度为 100%
            playing={isPlaying} // 自动播放
            onPlay={handlePlay}    // 添加播放事件处理
            onPause={handlePause}  // 添加暂停事件处理
            onProgress={handleProgress}
            onError={(e) => console.error('Video error:', e)}
            onReady={() => {
              // 获取视频宽高比
              //const aspectRatio = player.aspectRatio || 16 / 9;
              // 设置视频方向
            }}
            style={{ // 添加样式以保持视频宽高比
              aspectRatio: videoOrientation === 'landscape' ? '16/9' : '9/16',
              objectFit: 'contain', // 保持视频宽高比
            }}
          />
        </Box>
        <Box sx={{ width: isSmallScreen ? '100%' : '30%', p: 0, overflowY: 'auto', minWidth: isSmallScreen ? 0 : 390 }}> {/* 根据屏幕大小设置宽度 */}
          {lessonPlan && (
            <>
              {renderTabs()}
              {renderTabContent()}
            </>
          )}
        </Box>


      </Box>



      <Dialog
        open={isQuestionDialogOpen}
        onClose={handleCloseQuestionDialog}
        fullScreen={isSmallScreen}
        PaperProps={{
          sx: {
            zIndex: 1000 // 设置 z-index 为 1000
          }
        }}
      >
        <QuestionInteraction
          videoFilename={stored_filename}
          segment={lastSegment.current || {}} // 修改：使用lastSegment.current
          summary={lessonPlan?.summary || ''}
          onClose={handleCloseQuestionDialog}
          onQuestionAnswered={handleQuestionAnsweredCallback}
        />
      </Dialog>

    </div >
  );
}

export default PlayPage;