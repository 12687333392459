import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { GlobalData } from '../global_data';
import {
  Fullscreen as FullscreenIcon,
  FullscreenExit as FullscreenExitIcon,
  PlayArrow as PlayArrowIcon,
  Pause as PauseIcon,
  Subtitles as SubtitlesIcon,
  SubtitlesOff as SubtitlesOffIcon,
} from '@mui/icons-material';
import styled from '@emotion/styled';

// 格式化时间的工具函数
const formatTime = (timeInSeconds) => {
  const minutes = Math.floor(timeInSeconds / 60);
  const seconds = Math.floor(timeInSeconds % 60);
  return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
};

// 添加样式组件
const SubtitleContainer = styled.div`
  position: absolute;
  bottom: 60px;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  z-index: 2;
  pointer-events: auto;
  user-select: none;
  width: 100%;
`;

const SubtitleBase = styled.span`
  background: transparent;
  color: white;
  padding: 5px 2px;
  margin: 0;
  display: inline-block;
  font-size: 24px;
  font-weight: bold;
  filter: drop-shadow(0px 0px 1px black) 
         drop-shadow(0px 0px 1px black)
         drop-shadow(0px 0px 1px black);
`;

const SubtitleText = styled(SubtitleBase)`
  ${props => props.clickable && `
    cursor: pointer;
    &:hover {
      color: #ffbb34;
      text-decoration: underline;
    }
  `}
`;

const DictionaryPopup = styled.div`
  position: fixed;
  background: rgba(255, 255, 255, 0.98);
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 15px;
  min-width: 250px;
  max-width: 400px;
  box-shadow: 0 4px 12px rgba(0,0,0,0.15);
  z-index: 1000;
  display: ${props => props.show ? 'block' : 'none'};
  left: ${props => props.x}px;
  top: ${props => props.y}px;
  max-height: 300px;
  overflow-y: auto;
`;

const WordTitle = styled.h3`
  margin: 0 0 8px 0;
  color: #333;
  font-size: 18px;
`;

const Phonetic = styled.p`
  color: #666;
  margin: 0 0 8px 0;
  font-style: italic;
`;

const Translation = styled.div`
  margin: 8px 0;
  padding: 8px;
  background: #f5f5f5;
  border-radius: 4px;
`;

const Definition = styled.div`
  margin: 8px 0;
  line-height: 1.4;
`;

const PlayVideo2 = () => {
  // Refs
  const videoRef = useRef(null);
  const containerRef = useRef(null);
  const progressRef = useRef(null);
  const controlsTimeoutRef = useRef(null);
  const lastTouchX = useRef(null);

  // States
  const { videoFilename } = useParams();
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [loadedProgress, setLoadedProgress] = useState(0);
  const [showControls, setShowControls] = useState(true);
  const [isDragging, setIsDragging] = useState(false);
  const [tempProgress, setTempProgress] = useState(null);
  const [hoverPosition, setHoverPosition] = useState(null);
  const [subtitleUrl, setSubtitleUrl] = useState('');
  const [subtitleWords, setSubtitleWords] = useState([]);
  const [wordInfo, setWordInfo] = useState(null);
  const [popupPosition, setPopupPosition] = useState({ x: 0, y: 0 });
  const [showPopup, setShowPopup] = useState(false);
  const [showSubtitles, setShowSubtitles] = useState(true);

  const videoUrl = `${GlobalData.serverURL}/getvideofile/${videoFilename}`;

  // 事件处理函数
  const handlePlayPause = () => {
    if (videoRef.current.paused) {
      videoRef.current.play();
      setIsPlaying(true);
    } else {
      videoRef.current.pause();
      setIsPlaying(false);
    }
  };

  // 处理进度条点击
  const handleProgressClick = (e) => {
    e.stopPropagation();
    const progressBar = progressRef.current;
    const rect = progressBar.getBoundingClientRect();
    const pos = Math.max(0, Math.min(1, (e.clientX - rect.left) / rect.width));
    videoRef.current.currentTime = pos * duration;
  };

  // 处理鼠标移动显示控制器
  const handleMouseMove = () => {
    setShowControls(true);
    if (controlsTimeoutRef.current) {
      clearTimeout(controlsTimeoutRef.current);
    }
    if (isPlaying) {
      controlsTimeoutRef.current = setTimeout(() => {
        if (!isDragging) {
          setShowControls(false);
        }
      }, 3000);
    }
  };

  // 处理键盘快捷键
  const handleKeyPress = (e) => {
    switch (e.code) {
      case 'Space':
        e.preventDefault();
        handlePlayPause();
        break;
      case 'ArrowLeft':
        e.preventDefault();
        videoRef.current.currentTime = Math.max(0, videoRef.current.currentTime - 5);
        break;
      case 'ArrowRight':
        e.preventDefault();
        videoRef.current.currentTime = Math.min(duration, videoRef.current.currentTime + 5);
        break;
      case 'KeyF':
        e.preventDefault();
        handleFullscreen();
        break;
      default:
        break;
    }
  };

  // 处理进度条拖动
  const handleProgressMouseDown = (e) => {
    e.stopPropagation();
    setIsDragging(true);
    const progressBar = progressRef.current;
    const rect = progressBar.getBoundingClientRect();
    const pos = Math.max(0, Math.min(1, (e.clientX - rect.left) / rect.width));
    setTempProgress(pos * duration);
    containerRef.current?.classList.add('dragging');
  };

  const handleProgressMove = (e) => {
    if (isDragging) {
      const progressBar = progressRef.current;
      const rect = progressBar.getBoundingClientRect();
      const pos = Math.max(0, Math.min(1, (e.clientX - rect.left) / rect.width));
      setTempProgress(pos * duration);
    }
  };

  const handleProgressMouseUp = () => {
    if (isDragging && tempProgress !== null) {
      videoRef.current.currentTime = tempProgress;
      setCurrentTime(tempProgress);
    }
    setIsDragging(false);
    setTempProgress(null);
    containerRef.current?.classList.remove('dragging');
  };

  // 处理移动端触摸
  const handleTouchStart = (e) => {
    lastTouchX.current = e.touches[0].clientX;
  };

  const handleTouchMove = (e) => {
    if (!lastTouchX.current) return;

    const deltaX = e.touches[0].clientX - lastTouchX.current;
    const seekTime = 30 * (deltaX / window.innerWidth); // 30秒为最大快进/快退时间
    videoRef.current.currentTime = Math.max(0, Math.min(duration, videoRef.current.currentTime + seekTime));

    lastTouchX.current = e.touches[0].clientX;
  };

  const handleTouchEnd = () => {
    lastTouchX.current = null;
  };

  // 添加日志来跟踪视频容器的高度变化
  const logContainerSize = () => {
    if (containerRef.current) {
      console.log('Container size:', {
        height: containerRef.current.offsetHeight,
        videoHeight: videoRef.current?.offsetHeight,
        clientHeight: containerRef.current.clientHeight,
      });
    }
  };

  // 分词函数
  const splitIntoWords = (text) => {
    const pattern = /([a-zA-Z]+[-'']?[a-zA-Z]+|[^a-zA-Z\s]+|\s+)/g;
    const matches = text.match(pattern) || [];
    return matches.map(token => ({
      text: token,
      isWord: /^[a-zA-Z]+[-'']?[a-zA-Z]+$/.test(token) && token.length >= 2,
      isSpace: /^\s+$/.test(token)
    }));
  };

  // 处理单词点击
  const handleWordClick = async (word, event) => {
    try {
      // 阻止事件冒泡
      event.stopPropagation();

      // 暂停视频
      if (videoRef.current) {
        videoRef.current.pause();
        setIsPlaying(false);
      }

      const cleanWord = word
        .replace(/[.,!?;:"]/g, '')
        .toLowerCase();

      const response = await fetch(`${GlobalData.serverURL}/query_word/${cleanWord}`);

      if (response.ok) {
        const data = await response.json();
        setWordInfo(data);

        const rect = event.target.getBoundingClientRect();
        setPopupPosition({
          x: rect.left,
          y: rect.top - 320
        });

        setShowPopup(true);
      }
    } catch (error) {
      console.error('查询单词失败:', error);
    }
  };

  // 在视频加载 useEffect 中添加字幕加载
  useEffect(() => {
    const fetchSubtitle = async () => {


      // 加载字幕
      try {
        const subtitleUrl = `${GlobalData.serverURL}/get_subtitle/${videoFilename}`;
        const subtitleResponse = await fetch(subtitleUrl);

        if (subtitleResponse.ok) {
          const subtitleBlob = await subtitleResponse.blob();
          const subtitleObjectUrl = URL.createObjectURL(subtitleBlob);
          setSubtitleUrl(subtitleObjectUrl);
        }
      } catch (subtitleError) {
        console.error('字幕加载错误:', subtitleError);
      }

    };

    fetchSubtitle();

    return () => {
      if (subtitleUrl) {
        URL.revokeObjectURL(subtitleUrl);
      }
    };
  }, [videoFilename]);

  // 点击外部关闭弹窗
  useEffect(() => {
    const handleClickOutside = (e) => {
      if (!e.target.closest('.dictionary-popup')) {
        setShowPopup(false);
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  // 视频事件监听
  useEffect(() => {
    const video = videoRef.current;
    if (!video) return;

    // 只在 videoUrl 改变时才重新加载视频
    if (!video.src) {
      video.load();
    }

    // 监听视频加载完成事件
    const handleMetadata = () => {
      setDuration(video.duration);
      console.log('Video loaded:', {
        height: video.offsetHeight,
        naturalHeight: video.videoHeight,
      });
      logContainerSize();
    };

    // 使用 addEventListener 而不是 onloadedmetadata
    video.addEventListener('loadedmetadata', handleMetadata);

    // 监听播放状态
    const handlePlay = () => {
      setIsPlaying(true);
    };

    const handlePause = () => {
      setIsPlaying(false);
    };

    video.addEventListener('play', handlePlay);
    video.addEventListener('pause', handlePause);

    // 监听播放进度更新
    const handleTimeUpdate = () => {
      if (!video.paused) {
        setCurrentTime(video.currentTime);
      }
    };

    video.addEventListener('timeupdate', handleTimeUpdate);

    // 添加加载进度监听
    const handleProgress = () => {
      if (video.buffered.length > 0) {
        // 获取最后一个已加载片段的结束位置
        const bufferedEnd = video.buffered.end(video.buffered.length - 1);
        const duration = video.duration;
        setLoadedProgress((bufferedEnd / duration) * 100);
      }
    };

    video.addEventListener('progress', handleProgress);
    // 也监听 timeupdate 事件来更新加载进度
    video.addEventListener('timeupdate', handleProgress);

    // 添加字幕轨道事件监听
    const handleTrackLoad = () => {
      console.log('字幕轨道加载完成');
      const track = video.textTracks[0];
      if (track) {
        console.log('找到字幕轨道:', track);
        track.mode = 'hidden';

        track.addEventListener('cuechange', () => {
          console.log('字幕更新:', track.activeCues);
          if (track.activeCues && track.activeCues.length > 0) {
            const cueText = track.activeCues[0].text;
            console.log('当前字幕文本:', cueText);
            const words = splitIntoWords(cueText);
            setSubtitleWords(words);
          } else {
            setSubtitleWords([]);
          }
        });
      }
    };

    // 当视频加载完成后设置字幕轨道
    video.addEventListener('loadedmetadata', () => {
      if (video.textTracks.length > 0) {
        handleTrackLoad();
      }
    });

    // 监听字幕轨道加载
    video.textTracks.addEventListener('addtrack', handleTrackLoad);

    // 清理函数
    return () => {
      video.removeEventListener('loadedmetadata', handleMetadata);
      video.removeEventListener('play', handlePlay);
      video.removeEventListener('pause', handlePause);
      video.removeEventListener('timeupdate', handleTimeUpdate);
      video.removeEventListener('progress', handleProgress);
      video.removeEventListener('timeupdate', handleProgress);

      // 清理字幕相关事件监听
      if (video.textTracks.length > 0) {
        const track = video.textTracks[0];
        if (track) {
          track.removeEventListener('cuechange', () => { });
        }
      }
      video.textTracks.removeEventListener('addtrack', handleTrackLoad);
    };
  }, [videoUrl]); // 只在 videoUrl 改变时重新执行

  // 样式注入
  useEffect(() => {
    const styleSheet = document.createElement("style");
    styleSheet.textContent = styles(showControls);
    document.head.appendChild(styleSheet);

    return () => {
      document.head.removeChild(styleSheet);
    };
  }, [showControls]);

  // 全屏和键盘事件监听
  useEffect(() => {
    const handleFullscreenChange = () => {
      const isFullscreenNow = (
        document.fullscreenElement === containerRef.current ||
        document.webkitFullscreenElement === containerRef.current ||
        document.mozFullScreenElement === containerRef.current ||
        document.msFullscreenElement === containerRef.current
      );
      setIsFullscreen(isFullscreenNow);
    };

    document.addEventListener('fullscreenchange', handleFullscreenChange);
    document.addEventListener('webkitfullscreenchange', handleFullscreenChange);
    document.addEventListener('mozfullscreenchange', handleFullscreenChange);
    document.addEventListener('MSFullscreenChange', handleFullscreenChange);

    // 添加键盘事件监听
    document.addEventListener('keydown', handleKeyPress);
    document.addEventListener('mousemove', handleProgressMove);
    document.addEventListener('mouseup', handleProgressMouseUp);

    return () => {
      document.removeEventListener('fullscreenchange', handleFullscreenChange);
      document.removeEventListener('webkitfullscreenchange', handleFullscreenChange);
      document.removeEventListener('mozfullscreenchange', handleFullscreenChange);
      document.removeEventListener('MSFullscreenChange', handleFullscreenChange);
      document.removeEventListener('keydown', handleKeyPress);
      document.removeEventListener('mousemove', handleProgressMove);
      document.removeEventListener('mouseup', handleProgressMouseUp);
    };
  }, [handleKeyPress, handleProgressMove, handleProgressMouseUp]);

  // 添加处理视频点击的函数
  const handleVideoClick = (e) => {
    // 如果点击的是控制器或控制器内的元素，不处理点击事件
    if (e.target.closest('.video-controls')) {
      return;
    }
    handlePlayPause();
  };

  // 在其他事件处理函数旁边添加 handleFullscreen 函数
  const handleFullscreen = async () => {
    try {
      if (!document.fullscreenElement) {
        if (containerRef.current.requestFullscreen) {
          await containerRef.current.requestFullscreen();
        } else if (containerRef.current.webkitRequestFullscreen) {
          await containerRef.current.webkitRequestFullscreen();
        } else if (containerRef.current.mozRequestFullScreen) {
          await containerRef.current.mozRequestFullScreen();
        } else if (containerRef.current.msRequestFullscreen) {
          await containerRef.current.msRequestFullscreen();
        }
      } else {
        if (document.exitFullscreen) {
          await document.exitFullscreen();
        } else if (document.webkitExitFullscreen) {
          await document.webkitExitFullscreen();
        } else if (document.mozCancelFullScreen) {
          await document.mozCancelFullScreen();
        } else if (document.msExitFullscreen) {
          await document.msExitFullscreen();
        }
      }
    } catch (err) {
      console.error('全屏切换失败:', err);
    }
  };

  return (
    <div
      ref={containerRef}
      className="video-container"
      style={containerStyle}
      onClick={handleVideoClick}
      onMouseMove={handleMouseMove}
      onMouseEnter={() => setShowControls(true)}
      onMouseLeave={() => isPlaying && setShowControls(false)}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
    >
      <video
        ref={videoRef}
        style={videoStyle}
        playsInline
        preload="auto"
        autoPlay
        className="custom-video-player"
      >
        <source src={videoUrl} type="video/mp4" />
        {subtitleUrl && (
          <track
            label="字幕"
            kind="subtitles"
            srcLang="en"
            src={subtitleUrl}
            default
          />
        )}
        您的浏览器不支持 HTML5 视频播放器
      </video>

      <SubtitleContainer
        style={{
          opacity: showSubtitles ? 1 : 0,
          pointerEvents: showSubtitles ? 'auto' : 'none',
          visibility: showSubtitles ? 'visible' : 'hidden'
        }}
      >
        {subtitleWords.map((token, index) => (
          token.isSpace ? (
            <SubtitleBase key={index}>&nbsp;</SubtitleBase>
          ) : (
            <SubtitleText
              key={index}
              onClick={(e) => token.isWord ? handleWordClick(token.text, e) : null}
              clickable={token.isWord}
            >
              {token.text}
            </SubtitleText>
          )
        ))}
      </SubtitleContainer>

      <ControlBar
        isPlaying={isPlaying}
        currentTime={currentTime}
        duration={duration}
        loadedProgress={loadedProgress}
        isDragging={isDragging}
        tempProgress={tempProgress}
        hoverPosition={hoverPosition}
        isFullscreen={isFullscreen}
        progressRef={progressRef}
        onPlayPause={handlePlayPause}
        onProgressClick={handleProgressClick}
        onProgressMouseDown={handleProgressMouseDown}
        onProgressMouseMove={(e) => {
          const rect = e.currentTarget.getBoundingClientRect();
          const pos = Math.max(0, Math.min(1, (e.clientX - rect.left) / rect.width));
          setHoverPosition(pos * duration);
        }}
        onProgressMouseLeave={() => setHoverPosition(null)}
        onFullscreen={handleFullscreen}
        showSubtitles={showSubtitles}
        onSubtitlesToggle={() => setShowSubtitles(!showSubtitles)}
      />

      <DictionaryPopup
        className="dictionary-popup"
        show={showPopup}
        x={popupPosition.x}
        y={popupPosition.y}
      >
        {wordInfo && (
          <>
            <WordTitle>{wordInfo.word}</WordTitle>
            {wordInfo.phonetic && <Phonetic>[{wordInfo.phonetic}]</Phonetic>}
            {wordInfo.pos && <div>词性: {wordInfo.pos}</div>}
            {wordInfo.translation && (
              <Translation>
                <strong>翻译:</strong> {wordInfo.translation}
              </Translation>
            )}
            {wordInfo.definition && (
              <Definition>
                <strong>释义:</strong> {wordInfo.definition}
              </Definition>
            )}
            {wordInfo.collins && <div>柯林斯星级: {wordInfo.collins}</div>}
          </>
        )}
      </DictionaryPopup>
    </div>
  );
};

// 控制栏组件
const ControlBar = ({
  isPlaying,
  currentTime,
  duration,
  loadedProgress,
  isDragging,
  tempProgress,
  hoverPosition,
  isFullscreen,
  progressRef,
  onPlayPause,
  onProgressClick,
  onProgressMouseDown,
  onProgressMouseMove,
  onProgressMouseLeave,
  onFullscreen,
  showSubtitles,
  onSubtitlesToggle,
}) => {
  return (
    <div className="video-controls">
      <button
        onClick={onPlayPause}
        style={buttonStyle}
      >
        {isPlaying ? <PauseIcon /> : <PlayArrowIcon />}
      </button>

      <span style={timeStyle}>
        {formatTime(currentTime)}
      </span>

      <div
        ref={progressRef}
        className="progress-bar"
        onClick={onProgressClick}
        onMouseDown={onProgressMouseDown}
        onMouseMove={onProgressMouseMove}
        onMouseLeave={onProgressMouseLeave}
        style={{ cursor: 'pointer' }}
      >
        <div
          className="progress-loaded"
          style={{ width: `${loadedProgress}%` }}
        />
        <div
          className="progress-played"
          style={{ width: `${((isDragging && tempProgress !== null ? tempProgress : currentTime) / duration) * 100}%` }}
        />
        <div
          className="progress-handle"
          style={{
            left: `${((hoverPosition !== null ? hoverPosition : (isDragging && tempProgress !== null ? tempProgress : currentTime)) / duration) * 100}%`
          }}
        />
        <div
          className="progress-time-tooltip"
          style={{
            left: `${((hoverPosition !== null ? hoverPosition : (isDragging && tempProgress !== null ? tempProgress : currentTime)) / duration) * 100}%`
          }}
        >
          {formatTime(hoverPosition !== null ? hoverPosition : (isDragging && tempProgress !== null ? tempProgress : currentTime))}
        </div>
      </div>

      <span style={timeStyle}>
        {formatTime(duration)}
      </span>

      <button
        onClick={onSubtitlesToggle}
        style={buttonStyle}
        title={showSubtitles ? "隐藏字幕" : "显示字幕"}
      >
        {showSubtitles ? <SubtitlesIcon /> : <SubtitlesOffIcon />}
      </button>

      <button
        onClick={onFullscreen}
        style={buttonStyle}
      >
        {isFullscreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
      </button>
    </div>
  );
};

// 样式定义
const containerStyle = {
  width: '100%',
  maxWidth: '800px',
  margin: '0 auto',
  position: 'relative',
  backgroundColor: '#000',
};

const videoStyle = {
  width: '100%',
  height: '100%',
  display: 'block',
  objectFit: 'contain',
};

const buttonStyle = {
  background: 'none',
  border: 'none',
  color: 'white',
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center'
};

const timeStyle = {
  color: 'white',
  fontSize: '14px'
};

const styles = (showControls) => `
  .video-container {
    position: relative;
    overflow: hidden;
    aspect-ratio: 16 / 9;
  }

  .custom-video-player {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  
  .video-controls {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.7);
    padding: 10px;
    display: flex;
    align-items: center;
    gap: 10px;
    transition: opacity 0.3s ease;
    opacity: ${showControls ? '1' : '0'};
    pointer-events: ${showControls ? 'auto' : 'none'};
    z-index: 1;
    height: 40px;
    transform: translateZ(0);
  }

  .progress-bar {
    position: relative;
    height: 5px;
    background: rgba(255, 255, 255, 0.3);
    cursor: pointer;
    flex-grow: 1;
    transition: height 0.1s ease;
    border-radius: 5px;
  }

  .progress-bar:hover,
  .progress-bar:active {
    height: 8px;
  }

  .progress-loaded {
    position: absolute;
    height: 100%;
    background: rgba(255, 255, 255, 0.3);  /* 调整已加载部分的颜色 */
    pointer-events: none;
    transition: width 0.3s ease;  /* 添加平滑过渡效果 */
    border-radius: 5px;
  }

  .progress-played {
    position: absolute;
    height: 100%;
    background: #ffbb34;  /* 修改为新的颜色 */
    pointer-events: none;
    z-index: 1;
    border-radius: 5px;
  }

  .video-container.dragging .progress-bar {
    height: 8px;
  }

  .progress-handle {
    position: absolute;
    width: 12px;
    height: 12px;
    background: #ffbb34;  /* 同时修改圆点的颜色，保持一致性 */
    border-radius: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    pointer-events: none;
    opacity: 0;
    transition: opacity 0.2s ease-in;
  }

  .progress-time-tooltip {
    position: absolute;
    background: rgba(0, 0, 0, 0.8);
    color: white;
    padding: 4px 8px;
    border-radius: 4px;
    font-size: 12px;
    bottom: 20px;
    transform: translateX(-50%);
    pointer-events: none;
    opacity: 0;
    transition: opacity 0.2s ease-in;
  }

  .progress-bar:hover .progress-handle,
  .progress-bar:hover .progress-time-tooltip,
  .video-container.dragging .progress-handle,
  .video-container.dragging .progress-time-tooltip {
    opacity: 1;
    visibility: visible;
    transition: opacity 0.2s ease-in;
  }

  .progress-handle,
  .progress-time-tooltip {
    visibility: hidden;
    transition: opacity 0s, visibility 0s;
  }
`;

export default PlayVideo2;