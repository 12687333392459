import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  Card,
  CardContent,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Snackbar,
  Alert,
  Grid2,
  LinearProgress,
} from '@mui/material';

import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';

import { GlobalData } from '../global_data';

import MyAppBar from '../components/MyAppBar'; //  导入 MyAppBar




function UploadPage() {
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [isVideoUploaded, setIsVideoUploaded] = useState(false);

  const [showDialog, setShowDialog] = useState(false);

  const [uploadProgress, setUploadProgress] = useState(0); // 上传进度
  const [uploadSpeed, setUploadSpeed] = useState(0); // 上传速度
  const [uploadStatus, setUploadStatus] = useState(''); // 上传状态信息
  const [uploadFileName, setUploadFileName] = useState(''); // 上传文件名


  const unique_id = localStorage.getItem('unique_id');



  const navigate = useNavigate();


  //console.log('unique_id', unique_id);


  // 选择要上传的视频
  const handleVideoChange = (event) => {
    //console.log('selectedVideo', event.target.files[0]);
    const file = event.target.files[0];
    if (file) {
      setSelectedVideo(file);
      setUploadFileName(file.name); // 设置文件名
      setUploadStatus(''); // 开始上传时设置状态
      uploadVideo(file); // 选择文件后立即上传
      // 可以在这里添加其他逻辑，例如预览视频
    } else {
      setSelectedVideo(null);
      setUploadStatus(''); // 清空状态信息
    }
    //console.log('unique_id', unique_id);
  };

  // 上传视频
  const uploadVideo = async (file) => {

    if (!file) {
      console.log('!file');
      return;
    }

    const formData = new FormData();
    formData.append('videos', file);
    formData.append('unique_id', unique_id);
    let lastLoaded = 0;
    let lastTime = Date.now();


    try {
      const response = await axios.post(
        `${GlobalData.serverURL}/upload/`,
        formData,
        {
          onUploadProgress: (progressEvent) => {
            // 计算进度
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            setUploadProgress(percentCompleted);

            //计算速度
            const now = Date.now();
            const timeDiff = now - lastTime;

            if (timeDiff > 3000) {
              // 重置速度计算相关变量
              const bytesDiff = progressEvent.loaded - lastLoaded;
              const speed = bytesDiff / (timeDiff || 1);
              setUploadSpeed(Math.round(speed)); // 更新状态
              lastLoaded = progressEvent.loaded; // 更新上次加载的字节数
              lastTime = now; // 更新上次更新时间
            }



          },
        }
      );

      if (response.status === 200) {
        console.log('视频上传成功');
        setIsVideoUploaded(true);
        setUploadStatus(`上传成功`); // 更新状态

        //转到HomePage
        navigate('/home');

      } else {
        console.error(`上传失败:`, response.status);

        // 错误提示
        setUploadStatus(`上传失败: ${response.statusText}`);
      }
    } catch (error) {
      console.error(`上传发生错误:`, error);
      // 错误提示
      if (error.response && error.response.data && error.response.data.detail) {
        // 如果错误响应中包含detail字段，则使用该字段作为上传状态信息
        setUploadStatus(`上传发生错误:${error.response.data.detail}`);
      } else {
        // 否则，使用错误的message作为上传状态信息
        setUploadStatus(`上传发生错误:${error.message}`);
      }
    }
  };

  // 退出确认对话框
  const handleCloseDialog = () => {
    setShowDialog(false);
  };

  // 确认退出转到HomePage
  const handleConfirmDialog = () => {
    navigate('/home');
  };




  return (
    <div>
      <MyAppBar title="上传视频" showSearch={false} showAdd={false} showTabs={false} /> {/* 修改处 */}
      <Box style={{ paddingTop: '12px', display: 'flex', justifyContent: 'center', width: '100%' }} >
        <Grid2 container spacing={2} width={"80%"}>
          <Grid2 item={"true"} size={12} >
            {/* 视频选择和预览 */}
            <Card style={{
              padding: '48px',
              backgroundColor: '#2E32380D',
              border: 'none', // 确保没有边框
              boxShadow: 'none', // 确保没有阴影
            }}>
              {!isVideoUploaded && !selectedVideo && (
                // 待上传状态 UI
                <Box
                  sx={{
                    height: 200,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    border: '1px dashed #ccc',
                    borderRadius: 1,
                    cursor: 'pointer',
                    '&:hover': {
                      backgroundColor: 'white',
                    },
                  }}
                  onClick={() => { document.getElementById('videoInput').click() }}
                >
                  {/* 上传图标 */}
                  <CloudUploadOutlinedIcon style={{ fontSize: 48, color: "grey" }} />
                  <Typography variant="body1" color="textSecondary">
                    点击上传
                  </Typography>
                  {/* 限制说明 */}
                  <Box sx={{ mt: 2 }}>
                    <Typography variant="caption" color="textSecondary">
                      支持常用视频格式，推荐 mp4、webm。
                    </Typography>
                    <Box /> {/* 添加间隙 */}
                    <Typography variant="caption" color="textSecondary">
                      视频文件大小不超过 200M，时长在 10 分钟以内。
                    </Typography>
                    <Box /> {/* 添加间隙 */}
                    <Typography variant="caption" color="textSecondary">
                      分辨率范围：720P - 1080P。
                    </Typography>
                  </Box>
                </Box>
              )}

              {/* 文件选择后显示上传进度 */}
              {selectedVideo && !isVideoUploaded && (
                <Box sx={{ height: 200, position: 'relative' }}>
                  <Typography
                    variant="body2"
                  >
                    {uploadFileName}
                  </Typography>

                  <LinearProgress
                    variant="determinate"
                    value={uploadProgress}
                    sx={{ height: 8, borderRadius: 4 }}
                  />
                  <Typography
                    variant="body2"
                    sx={{ textAlign: 'right' }}
                  >
                    {uploadProgress}% ({uploadSpeed} KB/s)
                  </Typography>
                  <Box height={20} /> {/* 添加间隙 */}
                  <Typography
                    variant="body2"
                    color="red"
                    sx={{ textAlign: 'center' }}
                  >
                    {uploadStatus}
                  </Typography>

                </Box>
              )}
              <CardContent>
                <input
                  type="file"
                  accept="video/*"
                  onChange={handleVideoChange}
                  id="videoInput"
                  style={{ display: 'none' }}
                />
              </CardContent>
            </Card>
          </Grid2>
        </Grid2>
      </Box>



      {/* 退出确认对话框 */}
      <Dialog open={showDialog} onClose={handleCloseDialog}>
        <DialogTitle>提示</DialogTitle>
        <DialogContent>
          <DialogContentText>确认退出编辑吗？</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>取消</Button>
          <Button onClick={handleConfirmDialog}>确认</Button>
        </DialogActions>
      </Dialog>

    </div >
  );
}

export default UploadPage;