import React, { useState, useEffect } from 'react';
import { Box, Typography, Button, IconButton } from '@mui/material';
import { GlobalData } from '../global_data';
import axios from 'axios';
import MyAppBar from './MyAppBar';
import StarIcon from '@mui/icons-material/Star';
import ChartIcon from '@mui/icons-material/ShowChart';
import CalendarIcon from '@mui/icons-material/CalendarToday';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, LabelList } from 'recharts';

function PointsDetailPage() {
  const [totalPoints, setTotalPoints] = useState(0);
  const [viewMode, setViewMode] = useState('calendar');
  const [calendarData, setCalendarData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [hoverDetails, setHoverDetails] = useState(null);
  const [chartData, setChartData] = useState([]);
  const [currentDisplayMonth, setCurrentDisplayMonth] = useState(() => {
    const today = new Date();
    return {
      year: today.getFullYear(),
      month: today.getMonth()
    };
  });

  // 初始化数据
  useEffect(() => {
    fetchTotalPoints();
    fetchInitialRecords();
  }, []);

  // 在月份变化时获取图表数据
  useEffect(() => {
    if (viewMode === 'chart') {
      const fetchMonthlyDetails = async () => {
        try {
          // 计算月初和月末
          const firstDay = new Date(currentDisplayMonth.year, currentDisplayMonth.month, 1);
          const lastDay = new Date(currentDisplayMonth.year, currentDisplayMonth.month + 1, 0);

          // 格式化日期
          const startDate = formatDate(firstDay);
          const endDate = formatDate(lastDay);

          // 获取整月的积分记录
          const response = await axios.get(
            `${GlobalData.serverURL}/get_points_records/?start_date=${startDate}&end_date=${endDate}`
          );

          if (response.status === 200) {
            // 按日期分组积分记录
            const dailyPoints = {};
            response.data.records.forEach(record => {
              const date = record.create_time.split('T')[0];
              if (!dailyPoints[date]) {
                dailyPoints[date] = {
                  watching: 0,
                  answering: 0
                };
              }
              if (record.type === 1) {
                dailyPoints[date].watching += record.points;
              } else if (record.type === 2) {
                dailyPoints[date].answering += record.points;
              }
            });

            // 生成图表数据
            const dailyData = calendarData
              .filter(day => {
                const date = new Date(day.date);
                return date.getMonth() === currentDisplayMonth.month;
              })
              .map(day => {
                const points = dailyPoints[day.date] || { watching: 0, answering: 0 };
                return {
                  date: `${new Date(day.date).getDate()}日`,
                  watching: points.watching,
                  answering: points.answering,
                  points: points.watching + points.answering,
                  fullDate: day.date
                };
              });

            setChartData(dailyData);
          }
        } catch (error) {
          console.error('获取月度积分明细失败:', error);
        }
      };

      fetchMonthlyDetails();
    }
  }, [currentDisplayMonth, calendarData, viewMode]);

  const fetchTotalPoints = async () => {
    try {
      const response = await axios.get(`${GlobalData.serverURL}/get_total_points/`);
      if (response.status === 200) {
        setTotalPoints(response.data.total_points);
      }
    } catch (error) {
      console.error('获取总积分失败:', error);
    }
  };

  const fetchInitialRecords = async () => {
    try {
      setLoading(true);
      const today = new Date();
      const currentYear = today.getFullYear();
      const currentMonth = today.getMonth();

      // 获取本月1日
      const firstDayOfMonth = new Date(currentYear, currentMonth, 1);
      // 获取本月1日是星期几（0-6）
      const firstDayOfWeek = firstDayOfMonth.getDay();
      // 计算需要显示的上月天数
      const daysFromPrevMonth = firstDayOfWeek === 0 ? 6 : firstDayOfWeek - 1;

      // 计算开始日期（上月的最后几天）
      const startDate = new Date(firstDayOfMonth);
      startDate.setDate(startDate.getDate() - daysFromPrevMonth);

      // 获取本月最后一天
      const lastDayOfMonth = new Date(currentYear, currentMonth + 1, 0);
      // 计算月末是星期几（0-6）
      const lastDayOfWeek = lastDayOfMonth.getDay();
      // 如果月末不是周日，需要显示下月的前几天
      const daysToNextMonth = lastDayOfWeek === 0 ? 0 : 7 - lastDayOfWeek;

      // 计算结束日期
      const endDate = new Date(lastDayOfMonth);
      if (endDate > today) {
        // 如果月末超过今天，就用今天作为结束日期
        endDate.setTime(today.getTime());
      } else {
        // 否则显示到月末，并加上需要显示的下月天数
        endDate.setDate(endDate.getDate() + daysToNextMonth);
      }

      const response = await axios.get(
        `${GlobalData.serverURL}/get_points_records/?start_date=${formatDate(startDate)}&end_date=${formatDate(endDate)}`
      );

      if (response.status === 200) {
        const processedData = processRecords(response.data.records, startDate, endDate);
        setCalendarData(processedData);
        setCurrentDisplayMonth({ year: currentYear, month: currentMonth });

        // 如果是当前月，就禁用向下按钮
        const isCurrentMonth = currentYear === today.getFullYear() && currentMonth === today.getMonth();
        setHasMore(!isCurrentMonth);
      }
    } catch (error) {
      console.error('获取积分记录失败:', error);
    } finally {
      setLoading(false);
    }
  };

  const processRecords = (records, startDate, endDate) => {
    const dailyPoints = {};

    // 生成日期范围内的所有日期
    const currentDate = new Date(startDate);
    while (currentDate <= endDate) {
      const dateStr = formatDate(currentDate);
      dailyPoints[dateStr] = {
        date: dateStr,
        points: 0,
        records: []
      };
      currentDate.setDate(currentDate.getDate() + 1);
    }

    // 添加积分记录
    records.forEach(record => {
      const date = record.create_time.split('T')[0];
      if (dailyPoints[date]) {
        dailyPoints[date].points += record.points;
        dailyPoints[date].records.push(record);
      }
    });

    // 转换为数组并按日期排序
    return Object.values(dailyPoints).sort((a, b) => a.date.localeCompare(b.date));
  };

  const renderCalendarGrid = () => {
    const weekDays = ['一', '二', '三', '四', '五', '六', '日'];

    return (
      <Box sx={{ position: 'relative', pr: 3 }}>
        <Box sx={{
          position: 'absolute',
          right: -20,
          top: '50%',
          transform: 'translateY(-50%)',
          display: 'flex',
          flexDirection: 'column',
          gap: 1
        }}>
          <IconButton
            onClick={() => handlePageChange('up')}
            disabled={loading}
            sx={{
              bgcolor: 'white',
              boxShadow: '0 2px 8px rgba(0,0,0,0.05)',
              '&:hover': {
                bgcolor: 'rgba(255, 187, 52, 0.1)',
              },
              '&.Mui-disabled': {
                bgcolor: '#f8f8f8',
              }
            }}
          >
            <ArrowUpwardIcon sx={{
              color: loading ? '#ccc' : '#ffbb34',
            }} />
          </IconButton>
          <IconButton
            onClick={() => handlePageChange('down')}
            disabled={loading || !hasMore}
            sx={{
              bgcolor: 'white',
              boxShadow: '0 2px 8px rgba(0,0,0,0.05)',
              '&:hover': {
                bgcolor: 'rgba(255, 187, 52, 0.1)',
              },
              '&.Mui-disabled': {
                bgcolor: '#f8f8f8',
              }
            }}
          >
            <ArrowDownwardIcon sx={{
              color: (loading || !hasMore) ? '#ccc' : '#ffbb34',
            }} />
          </IconButton>
        </Box>

        <Box sx={{
          display: 'grid',
          gridTemplateColumns: 'repeat(7, 1fr)',
          borderBottom: '1px solid #eee',
          mb: 0.5
        }}>
          {weekDays.map(day => (
            <Box key={day} sx={{
              textAlign: 'center',
              color: 'text.secondary',
              py: 0.5
            }}>
              <Typography variant="body2">{day}</Typography>
            </Box>
          ))}
        </Box>

        {/* 修改日期渲染逻辑 */}
        {(() => {
          const today = new Date();
          const currentYear = currentDisplayMonth.year;
          const currentMonth = currentDisplayMonth.month;
          const days = [];

          // 获取当月第一天
          const firstDayOfMonth = new Date(currentYear, currentMonth, 1);
          // 获取当月最后一天
          const lastDayOfMonth = new Date(currentYear, currentMonth + 1, 0);
          // 获取当月第一天是星期几（0-6）
          const firstDayOfWeek = firstDayOfMonth.getDay() || 7; // 将周日的0转换为7
          // 获取上月需要显示的天数
          const daysFromPrevMonth = firstDayOfWeek === 1 ? 0 : firstDayOfWeek - 1;

          // 添加上月的日期
          if (daysFromPrevMonth > 0) {
            const prevMonthLastDay = new Date(currentYear, currentMonth, 0);
            for (let i = daysFromPrevMonth - 1; i >= 0; i--) {
              const date = new Date(currentYear, currentMonth - 1, prevMonthLastDay.getDate() - i);
              days.push({
                date: formatDate(date),
                day: date.getDate(),
                isCurrentMonth: false,
                isToday: false
              });
            }
          }

          // 添加当月的日期
          for (let i = 1; i <= lastDayOfMonth.getDate(); i++) {
            const date = new Date(currentYear, currentMonth, i);
            const formattedDate = formatDate(date);
            const dayData = calendarData.find(d => d.date === formattedDate) || {
              date: formattedDate,
              points: 0,
              records: []
            };

            days.push({
              ...dayData,
              day: i,
              isCurrentMonth: true,
              isFirstDay: i === 1,
              isToday: formatDate(date) === formatDate(today)
            });
          }

          // 添加下月的日期以补齐最后一行
          const lastDayOfWeek = lastDayOfMonth.getDay() || 7;
          const daysToNextMonth = lastDayOfWeek === 7 ? 0 : 7 - lastDayOfWeek;

          if (daysToNextMonth > 0) {
            for (let i = 1; i <= daysToNextMonth; i++) {
              const date = new Date(currentYear, currentMonth + 1, i);
              days.push({
                date: formatDate(date),
                day: i,
                isCurrentMonth: false,
                isToday: false
              });
            }
          }

          // 将日期分组为每周
          const weeks = [];
          for (let i = 0; i < days.length; i += 7) {
            weeks.push(days.slice(i, i + 7));
          }

          // 渲染日历网格
          return weeks.map((week, weekIndex) => (
            <Box key={weekIndex} sx={{
              display: 'grid',
              gridTemplateColumns: 'repeat(7, 1fr)',
              gap: '4px',
              mb: 0.5
            }}>
              {week.map((dayData, dayIndex) => (
                <Box
                  key={dayIndex}
                  onMouseEnter={() => handleDayHover(dayData)}
                  onMouseLeave={() => setHoverDetails(null)}
                  sx={{
                    p: 1,
                    position: 'relative',
                    borderRadius: 1,
                    bgcolor: dayData.isToday ? 'rgba(255, 187, 52, 0.1)' :
                      dayData.isCurrentMonth ? 'transparent' : '#f8f8f8',
                    border: dayData.isToday ? '2px solid #ffbb34' : 'none',
                    cursor: dayData.points > 0 ? 'pointer' : 'default',
                    opacity: dayData.isCurrentMonth ? 1 : 0.5,
                    '&:hover': {
                      bgcolor: 'rgba(255, 187, 52, 0.1)',
                      boxShadow: '0 2px 8px rgba(0,0,0,0.05)'
                    },
                    height: '50px',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between'
                  }}
                >
                  {/* 日期和月份显示 */}
                  <Box sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    width: '100%',
                  }}>
                    <Typography
                      sx={{
                        fontWeight: dayData.isToday ? 'bold' : 'normal',
                        color: dayData.isToday ? '#ffbb34' : 'inherit',
                        fontSize: '0.9rem',
                        lineHeight: 1,
                      }}
                    >
                      {dayData.day}
                    </Typography>

                    {/* 月份显示（1日显示） */}
                    {dayData.isFirstDay && (
                      <Typography
                        sx={{
                          fontSize: '0.9rem',
                          fontWeight: 'bold',
                          color: '#666',
                          lineHeight: 1,
                        }}
                      >
                        {`${new Date(dayData.date).getMonth() + 1}月`}
                      </Typography>
                    )}
                  </Box>

                  {/* 积分显示 - 使用空白占位符保持布局一致 */}
                  <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 0.2,
                    height: '20px',
                    visibility: dayData.points > 0 ? 'visible' : 'hidden'
                  }}>
                    <StarIcon sx={{ color: '#ffbb34', fontSize: 12 }} />
                    <Typography sx={{
                      color: '#ffbb34',
                      fontSize: '0.75rem',
                      lineHeight: 1,
                    }}>
                      +{dayData.points || 0}
                    </Typography>
                  </Box>

                  {/* 悬浮提示框 */}
                  {hoverDetails && hoverDetails.date === dayData.date && (
                    <Box
                      sx={{
                        position: 'absolute',
                        top: '100%',
                        left: '50%',
                        transform: 'translateX(-50%)',
                        bgcolor: 'white',
                        boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
                        borderRadius: 1,
                        p: 1.5,
                        zIndex: 1000,
                        minWidth: '150px',
                        border: '1px solid #eee'
                      }}
                    >
                      {hoverDetails.details.watching.length > 0 && (
                        <Box sx={{ mb: hoverDetails.details.answering.length > 0 ? 1 : 0 }}>
                          <Box sx={{
                            display: 'flex',
                            alignItems: 'center',
                            color: 'text.secondary',
                            mb: 0.5,
                            fontSize: '0.9rem',
                            gap: 0.5
                          }}>
                            观看视频：
                            <StarIcon sx={{ color: '#ffbb34', fontSize: 14 }} />
                            ×
                            {hoverDetails.details.watching.reduce((sum, r) => sum + r.points, 0)}
                          </Box>
                        </Box>
                      )}

                      {hoverDetails.details.answering.length > 0 && (
                        <Box>
                          <Box sx={{
                            display: 'flex',
                            alignItems: 'center',
                            color: 'text.secondary',
                            fontSize: '0.9rem',
                            gap: 0.5
                          }}>
                            口语互动：
                            <StarIcon sx={{ color: '#ffbb34', fontSize: 14 }} />
                            ×
                            {hoverDetails.details.answering.reduce((sum, r) => sum + r.points, 0)}
                          </Box>
                        </Box>
                      )}
                    </Box>
                  )}
                </Box>
              ))}
            </Box>
          ));
        })()}
      </Box>
    );
  };

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const handleDayHover = async (dayData) => {
    if (!dayData || !dayData.points) return;

    try {
      const response = await axios.get(
        `${GlobalData.serverURL}/get_points_details/?date=${dayData.date}`
      );

      if (response.status === 200) {
        // 将记录按类型分组
        const details = {
          watching: response.data.records.filter(r => r.type === 1),
          answering: response.data.records.filter(r => r.type === 2)
        };
        setHoverDetails({ date: dayData.date, details });
      }
    } catch (error) {
      console.error('获取日期明细失败:', error);
    }
  };

  const handlePageChange = async (direction) => {
    if (loading) return;

    try {
      setLoading(true);
      const today = new Date();

      if (direction === 'up') {
        // 计算上一个月
        const prevMonth = new Date(currentDisplayMonth.year, currentDisplayMonth.month - 1, 1);
        const targetYear = prevMonth.getFullYear();
        const targetMonth = prevMonth.getMonth();

        // 设置为目标月份的1号
        const monthFirstDay = new Date(targetYear, targetMonth, 1);

        // 获取月初1日是星期几（0-6）
        const firstDayOfWeek = monthFirstDay.getDay();
        // 如是周日，需要显示前6天；如果是周一，不需要显示上月的日期
        const daysFromPrevMonth = firstDayOfWeek === 0 ? 6 : firstDayOfWeek - 1;

        // 计算开始日期（月初1日之前的几天）
        const startDate = new Date(monthFirstDay);
        startDate.setDate(startDate.getDate() - daysFromPrevMonth);

        // 获取月末
        const lastDayOfMonth = new Date(targetYear, targetMonth + 1, 0);
        // 计算月末是星期几（0-6）
        const lastDayOfWeek = lastDayOfMonth.getDay();
        // 如果月末不是周日���需要示下月���几
        const daysToNextMonth = lastDayOfWeek === 0 ? 0 : 7 - lastDayOfWeek;

        // 计算结束日期（加上需要显示的下月天数）
        const endDate = new Date(lastDayOfMonth);
        endDate.setDate(endDate.getDate() + daysToNextMonth);

        const response = await axios.get(
          `${GlobalData.serverURL}/get_points_records/?start_date=${formatDate(startDate)}&end_date=${formatDate(endDate)}`
        );

        if (response.status === 200) {
          const processedData = processRecords(response.data.records, startDate, endDate);
          setCalendarData(processedData);
          setCurrentDisplayMonth({ year: targetYear, month: targetMonth });
          setHasMore(true);
        }
      } else if (direction === 'down') {
        // 计算下一个月
        const nextMonth = new Date(currentDisplayMonth.year, currentDisplayMonth.month + 1, 1);
        const today = new Date();

        // 如果下个月超过当前月份，则不允许翻页
        if (nextMonth.getFullYear() > today.getFullYear() ||
          (nextMonth.getFullYear() === today.getFullYear() && nextMonth.getMonth() > today.getMonth())) {
          setLoading(false);
          return;
        }

        const targetYear = nextMonth.getFullYear();
        const targetMonth = nextMonth.getMonth();

        // 设置为目标月份的1号
        const monthFirstDay = new Date(targetYear, targetMonth, 1);

        // 获取月初1日是星期几（0-6）
        const firstDayOfWeek = monthFirstDay.getDay();
        // 如果是日，需要显示前6天；如果是周一，不需要显示上月的日期
        const daysFromPrevMonth = firstDayOfWeek === 0 ? 6 : firstDayOfWeek - 1;

        // 计算开始日期（月初1日之前的几天）
        const startDate = new Date(monthFirstDay);
        startDate.setDate(startDate.getDate() - daysFromPrevMonth);

        // 获取月末或今天（取较早的日期）
        const lastDayOfMonth = new Date(targetYear, targetMonth + 1, 0);
        const endDate = lastDayOfMonth > today ? today : lastDayOfMonth;

        // console.log('向下翻页:', {
        //   targetYear,
        //   targetMonth: targetMonth + 1,
        //   startDate: formatDate(startDate),
        //   endDate: formatDate(endDate)
        // });

        const response = await axios.get(
          `${GlobalData.serverURL}/get_points_records/?start_date=${formatDate(startDate)}&end_date=${formatDate(endDate)}`
        );

        if (response.status === 200) {
          const processedData = processRecords(response.data.records, startDate, endDate);
          setCalendarData(processedData);
          setCurrentDisplayMonth({ year: targetYear, month: targetMonth });

          // 更新是否可以继续向下翻页：如果是当前月就禁用向下按钮
          const isCurrentMonth = targetYear === today.getFullYear() && targetMonth === today.getMonth();
          setHasMore(!isCurrentMonth);
        }
      }
    } catch (error) {
      console.error('Error fetching records:', error);
    } finally {
      setLoading(false);
    }
  };

  const renderTrendChart = () => {
    return (
      <Box sx={{
        height: 400,
        width: '100%',
        mt: 0
      }}>
        <Box sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          gap: 2,
          mb: 1
        }}>
          <IconButton
            onClick={() => handlePageChange('up')}
            disabled={loading}
            sx={{
              bgcolor: 'white',
              boxShadow: '0 2px 8px rgba(0,0,0,0.05)',
              '&:hover': {
                bgcolor: 'rgba(255, 187, 52, 0.1)',
              },
              '&.Mui-disabled': {
                bgcolor: '#f8f8f8',
              }
            }}
          >
            <ArrowBackIcon sx={{
              color: loading ? '#ccc' : '#ffbb34',
              '&.Mui-disabled': {
                color: '#ccc'
              }
            }} />
          </IconButton>

          <Typography
            variant="h6"
            sx={{
              color: '#666',
              fontSize: '1.2rem',
              fontWeight: 'bold',
            }}
          >
            {`${currentDisplayMonth.year}年${currentDisplayMonth.month + 1}月`}
          </Typography>

          <IconButton
            onClick={() => handlePageChange('down')}
            disabled={loading || !hasMore}
            sx={{
              bgcolor: 'white',
              boxShadow: '0 2px 8px rgba(0,0,0,0.05)',
              '&:hover': {
                bgcolor: 'rgba(255, 187, 52, 0.1)',
              },
              '&.Mui-disabled': {
                bgcolor: '#f8f8f8',
              }
            }}
          >
            <ArrowForwardIcon sx={{
              color: (loading || !hasMore) ? '#ccc' : '#ffbb34',
              '&.Mui-disabled': {
                color: '#ccc'
              }
            }} />
          </IconButton>
        </Box>

        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            data={chartData}
            margin={{
              top: 10,
              right: 30,
              left: 20,
              bottom: 60,
            }}
          >
            <XAxis
              dataKey="date"
              angle={-45}
              textAnchor="end"
              height={60}
              interval={0}
              tick={{ fontSize: 12 }}
            />
            <Tooltip
              formatter={(value, name) => {
                if (value === 0) return ['-'];
                const labels = {
                  watching: '观看视频：',
                  answering: '口语互动：'
                };
                return [
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                    {labels[name]}
                    <StarIcon sx={{ color: '#ffbb34', fontSize: 14 }} />
                    ×{value}
                  </Box>
                ];
              }}
              cursor={{ fill: 'rgba(255, 187, 52, 0.1)' }}
              position={{ y: 0 }}
              offset={50}
              wrapperStyle={{ top: '20px' }}
            />
            <Bar
              dataKey="watching"
              stackId="points"
              name="watching"
              fill="#b3e162"
              radius={[4, 4, 0, 0]}
            />
            <Bar
              dataKey="answering"
              stackId="points"
              name="answering"
              fill="#71ccff"
              radius={[4, 4, 0, 0]}
            >
              <LabelList
                dataKey={(entry) => entry.watching + entry.answering}
                position="top"
                content={({ x, y, width, value }) => {
                  if (value === 0) return null;
                  return (
                    <text
                      x={x + width / 2}
                      y={y - 10}
                      fill="#666"
                      textAnchor="middle"
                      fontSize={12}
                    >
                      {value}
                    </text>
                  );
                }}
              />
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </Box>
    );
  };

  return (
    <Box>
      <MyAppBar title="积分明细" showSearch={false} showAdd={false} showTabs={false} />
      <Box sx={{ p: 2, maxWidth: 800, margin: '0 auto' }}>
        <Box sx={{
          p: 2,
          mb: 2,
          backgroundColor: 'white',
          borderRadius: 2,
          boxShadow: '0 2px 8px rgba(0,0,0,0.05)'
        }}>
          <Box sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <StarIcon sx={{ color: '#ffbb34', mr: 1 }} />
              <Typography variant="h6">总积分: {totalPoints}</Typography>
            </Box>

            <Box sx={{ display: 'flex', gap: 1 }}>
              <Button
                size="small"
                variant={viewMode === 'calendar' ? 'contained' : 'outlined'}
                onClick={() => setViewMode('calendar')}
                startIcon={<CalendarIcon />}
              >
                日历图
              </Button>
              <Button
                size="small"
                variant={viewMode === 'chart' ? 'contained' : 'outlined'}
                onClick={() => setViewMode('chart')}
                startIcon={<ChartIcon />}
              >
                走势图
              </Button>
            </Box>
          </Box>
        </Box>

        <Box sx={{
          position: 'relative',
          backgroundColor: 'white',
          borderRadius: 2,
          boxShadow: '0 2px 8px rgba(0,0,0,0.05)',
          p: 2
        }}>
          <Box sx={{
            p: 1,
            pt: 0,
          }}>
            {viewMode === 'calendar' ? renderCalendarGrid() : (
              <Box sx={{
                height: 400,
                width: '100%',
                mt: 0
              }}>
                <Box sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: 2,
                  mb: 0.5,
                  mt: 0.5
                }}>
                  <IconButton
                    onClick={() => handlePageChange('up')}
                    disabled={loading}
                    sx={{
                      bgcolor: 'white',
                      boxShadow: '0 2px 8px rgba(0,0,0,0.05)',
                      '&:hover': {
                        bgcolor: 'rgba(255, 187, 52, 0.1)',
                      },
                      '&.Mui-disabled': {
                        bgcolor: '#f8f8f8',
                      }
                    }}
                  >
                    <ArrowBackIcon sx={{
                      color: loading ? '#ccc' : '#ffbb34',
                      '&.Mui-disabled': {
                        color: '#ccc'
                      }
                    }} />
                  </IconButton>

                  <Typography
                    variant="h6"
                    sx={{
                      color: '#666',
                      fontSize: '1.2rem',
                      fontWeight: 'bold',
                    }}
                  >
                    {`${currentDisplayMonth.year}年${currentDisplayMonth.month + 1}月`}
                  </Typography>

                  <IconButton
                    onClick={() => handlePageChange('down')}
                    disabled={loading || !hasMore}
                    sx={{
                      bgcolor: 'white',
                      boxShadow: '0 2px 8px rgba(0,0,0,0.05)',
                      '&:hover': {
                        bgcolor: 'rgba(255, 187, 52, 0.1)',
                      },
                      '&.Mui-disabled': {
                        bgcolor: '#f8f8f8',
                      }
                    }}
                  >
                    <ArrowForwardIcon sx={{
                      color: (loading || !hasMore) ? '#ccc' : '#ffbb34',
                      '&.Mui-disabled': {
                        color: '#ccc'
                      }
                    }} />
                  </IconButton>
                </Box>

                <ResponsiveContainer width="100%" height="100%">
                  <BarChart
                    data={chartData}
                    margin={{
                      top: 5,
                      right: 30,
                      left: 20,
                      bottom: 40,
                    }}
                  >
                    <XAxis
                      dataKey="date"
                      angle={-45}
                      textAnchor="end"
                      height={40}
                      interval={0}
                      tick={{ fontSize: 12 }}
                    />
                    <Tooltip
                      formatter={(value, name) => {
                        if (value === 0) return ['-'];
                        const labels = {
                          watching: '观看视频：',
                          answering: '口语互动：'
                        };
                        return [
                          <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                            {labels[name]}
                            <StarIcon sx={{ color: '#ffbb34', fontSize: 14 }} />
                            ×{value}
                          </Box>
                        ];
                      }}
                      cursor={{ fill: 'rgba(255, 187, 52, 0.1)' }}
                      position={{ y: 0 }}
                      offset={50}
                      wrapperStyle={{ top: '20px' }}
                    />
                    <Bar
                      dataKey="watching"
                      stackId="points"
                      name="watching"
                      fill="#b3e162"
                      radius={[4, 4, 0, 0]}
                    />
                    <Bar
                      dataKey="answering"
                      stackId="points"
                      name="answering"
                      fill="#71ccff"
                      radius={[4, 4, 0, 0]}
                    >
                      <LabelList
                        dataKey={(entry) => entry.watching + entry.answering}
                        position="top"
                        content={({ x, y, width, value }) => {
                          if (value === 0) return null;
                          return (
                            <text
                              x={x + width / 2}
                              y={y - 10}
                              fill="#666"
                              textAnchor="middle"
                              fontSize={12}
                            >
                              {value}
                            </text>
                          );
                        }}
                      />
                    </Bar>
                  </BarChart>
                </ResponsiveContainer>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default PointsDetailPage; 