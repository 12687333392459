import React, { useState, useEffect, useRef } from 'react';
import {
  Timeline,
  Card,
  Typography,
  Empty,
  Collapse,
  DatePicker,
  Radio,
  Space,
  Spin
} from 'antd';
import {
  ClockCircleOutlined,
  QuestionCircleOutlined,
  ExpandAltOutlined
} from '@ant-design/icons';
import { GlobalData } from '../global_data';
import { Rating, Box } from '@mui/material';
import axios from 'axios';
import dayjs from 'dayjs';
import debounce from 'lodash/debounce';
import MyAppBar from './MyAppBar';

const { Title, Text } = Typography;
const { Panel } = Collapse;
const { RangePicker } = DatePicker;

const StudyHistory = () => {
  const [records, setRecords] = useState([]);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);
  const [timeFilter, setTimeFilter] = useState('week'); // 'week', 'month', 'custom'
  const [dateRange, setDateRange] = useState([]);
  const containerRef = useRef(null);
  const pageSize = 10;

  // 处理时间筛选变化
  const handleTimeFilterChange = (e) => {
    const value = e.target.value;
    setTimeFilter(value);
    setPage(1);
    setRecords([]);
    setHasMore(true);

    if (value === 'week') {
      setDateRange([dayjs().subtract(7, 'day'), dayjs()]);
    } else if (value === 'month') {
      setDateRange([dayjs().subtract(1, 'month'), dayjs()]);
    } else {
      setDateRange([]); // 清空日期范围，等待用户选择
    }
  };

  // 处理自定义日期范围变化
  const handleDateRangeChange = (dates) => {
    setDateRange(dates);
    setPage(1);
    setRecords([]);
    setHasMore(true);
  };

  // 获取记录
  const fetchRecords = async () => {
    if (loading || !hasMore) return;

    try {
      setLoading(true);
      const params = {
        page,
        page_size: pageSize
      };

      // 添加日期范围参数
      if (dateRange?.length === 2) {
        params.start_date = dateRange[0].format('YYYY-MM-DD');
        params.end_date = dateRange[1].format('YYYY-MM-DD');
      }

      const response = await axios.get(`${GlobalData.serverURL}/study_records/`, {
        params
      });

      if (response.status === 200) {
        const newRecords = response.data.records;
        setRecords(prev => [...prev, ...newRecords]);
        setHasMore(newRecords.length === pageSize);
        setPage(prev => prev + 1);
      }
    } catch (error) {
      console.error('获学习记录失败:', error);
    } finally {
      setLoading(false);
    }
  };

  // 处理滚动加载
  const handleScroll = debounce(() => {
    if (containerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = containerRef.current;
      if (scrollHeight - scrollTop - clientHeight < 50) {
        fetchRecords();
      }
    }
  }, 200);

  useEffect(() => {
    fetchRecords();
  }, [dateRange]); // 当日期范围变化时重新加载

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      container.addEventListener('scroll', handleScroll);
      return () => container.removeEventListener('scroll', handleScroll);
    }
  }, [handleScroll]);

  const formatDuration = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    let result = '';
    if (hours > 0) result += `${hours}小时`;
    if (minutes > 0) result += `${minutes}分钟`;
    if (remainingSeconds > 0 || result === '') result += `${Math.floor(remainingSeconds)}秒`;

    return result;
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const now = new Date();
    const days = ['日', '一', '二', '三', '四', '五', '六'];

    // 将时间设置为00:00:00来比较日期
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const targetDate = new Date(date.getFullYear(), date.getMonth(), date.getDate());
    const diffDays = Math.floor((today - targetDate) / (1000 * 60 * 60 * 24));

    if (diffDays === 0) {
      return '今天 ' + date.toLocaleTimeString('zh-CN', { hour12: false });
    } else if (diffDays === 1) {
      return '昨天 ' + date.toLocaleTimeString('zh-CN', { hour12: false });
    } else if (diffDays === 2) {
      return '前天 ' + date.toLocaleTimeString('zh-CN', { hour12: false });
    } else {
      return `${date.toLocaleDateString('zh-CN')} 周${days[date.getDay()]} ${date.toLocaleTimeString('zh-CN', { hour12: false })}`;
    }
  };

  const handleVideoClick = (stored_filename) => {
    // 在新窗口打开视频
    window.open(`/play/${stored_filename}`, '_blank');
  };

  // 将问答记录按问题分组
  const groupQARecords = (qaRecords) => {
    const groupedRecords = {};
    qaRecords.forEach(qa => {
      if (!groupedRecords[qa.question]) {
        groupedRecords[qa.question] = [];
      }
      groupedRecords[qa.question].push(qa);
    });
    return groupedRecords;
  };

  const renderQARecords = (qaRecords) => {
    const groupedRecords = groupQARecords(qaRecords);

    return Object.entries(groupedRecords).map(([question, answers], index) => (
      <Card key={index} size="small" style={{ marginTop: 10 }}>
        <Text strong>问题：{question}</Text>
        <Collapse ghost>
          <Panel
            header={`${answers.length}个回答`}
            key="1"
            extra={<ExpandAltOutlined />}
          >
            {answers.map((answer, idx) => (
              <Box key={idx} sx={{ mt: 2, p: 2, bgcolor: '#f5f5f5', borderRadius: 1 }}>
                <Text>回答：{answer.answer}</Text>
                <Box sx={{ mt: 1 }}>
                  <Text>内容准确度：</Text>
                  <Rating
                    value={answer.contentAccuracy}
                    readOnly
                    size="small"
                  />
                </Box>
                <Box sx={{ mt: 1 }}>
                  <Text>语法准确度：</Text>
                  <Rating
                    value={answer.grammarAccuracy}
                    readOnly
                    size="small"
                  />
                </Box>
                <Text type="secondary">反馈：{answer.feedback}</Text>
              </Box>
            ))}
          </Panel>
        </Collapse>
      </Card>
    ));
  };

  const renderVideoInfo = (record) => {
    const thumbnailUrl = `${GlobalData.serverURL}/thumbnails/${record.stored_filename.replace('.mp4', '')}.jpg`;

    return (
      <Card
        hoverable
        style={{ marginBottom: 16, cursor: 'pointer' }}
        onClick={() => handleVideoClick(record.stored_filename)}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <img
            src={thumbnailUrl}
            alt={record.title || record.video_filename}
            style={{ width: 120, height: 67.5, objectFit: 'cover', marginRight: 16 }}
          />
          <div>
            <Title level={4} style={{ margin: 0 }}>{record.title || record.video_filename}</Title>
            <Text>观看时长：{formatDuration(record.watch_duration)}</Text>
          </div>
        </div>
      </Card>
    );
  };

  return (
    <>
      <MyAppBar title="学习记录" showSearch={false} showAdd={false} showTabs={false} />
      <div style={{ padding: '0 24px' }}>
        {/* 时间筛选控件 */}
        <Space direction="vertical" size="middle" style={{ marginBottom: '16px', marginTop: '16px' }}>
          <Radio.Group value={timeFilter} onChange={handleTimeFilterChange}>
            <Radio.Button value="week">近一周</Radio.Button>
            <Radio.Button value="month">近一月</Radio.Button>
            <Radio.Button value="custom">自定义</Radio.Button>
          </Radio.Group>

          {timeFilter === 'custom' && (
            <RangePicker
              value={dateRange}
              onChange={handleDateRangeChange}
              style={{ width: 300 }}
            />
          )}
        </Space>

        {/* 记录列表容器 */}
        <div
          ref={containerRef}
          style={{
            height: 'calc(100vh - 140px)',
            overflowY: 'auto',
            padding: '16px 16px 0',
          }}
        >
          {records.length > 0 ? (
            <>
              <Timeline
                items={records.map(record => ({
                  key: record.record_id,
                  dot: <ClockCircleOutlined style={{ fontSize: '16px' }} />,
                  children: (
                    <Card>
                      <Text strong>{formatDate(record.start_time)}</Text>
                      {renderVideoInfo(record)}

                      {record.qa_records.length > 0 && (
                        <Collapse>
                          <Panel
                            header={
                              <span>
                                <QuestionCircleOutlined /> 问答记录（{record.qa_records.length}条）
                              </span>
                            }
                            key="1"
                            extra={<ExpandAltOutlined />}
                          >
                            {renderQARecords(record.qa_records)}
                          </Panel>
                        </Collapse>
                      )}
                    </Card>
                  )
                }))}
              />

              {/* 加载状态和底部提示 */}
              <div style={{ textAlign: 'center', padding: '20px 0' }}>
                {loading ? (
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '8px' }}>
                    <Spin size="small" />
                    <Text type="secondary">加载中...</Text>
                  </div>
                ) : !hasMore ? (
                  <Text type="secondary" style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    position: 'relative'
                  }}>
                    <span style={{
                      position: 'relative',
                      padding: '0 20px',
                      backgroundColor: '#fff',
                      zIndex: 1
                    }}>
                      到底啦
                    </span>
                    <div style={{
                      position: 'absolute',
                      top: '50%',
                      left: '20%',
                      right: '20%',
                      height: '1px',
                      backgroundColor: '#e8e8e8',
                      zIndex: 0
                    }} />
                  </Text>
                ) : null}
              </div>
            </>
          ) : (
            <Empty description="暂无学习记录" />
          )}
        </div>
      </div>
    </>
  );
};

export default StudyHistory; 