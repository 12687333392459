import React, { useState, useEffect, useRef } from 'react';
import { AppBar, Toolbar, Typography, IconButton, Box } from '@mui/material';
import { Popover, Divider } from 'antd';
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import AccountCircleOutlined from '@mui/icons-material/AccountCircleOutlined';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { GlobalData } from '../global_data';
import axios from 'axios';
import HistoryIcon from '@mui/icons-material/History';
import LogoutIcon from '@mui/icons-material/Logout';
import StarIcon from '@mui/icons-material/Star';

// 添加样式组件
const StyledPopover = styled(Popover)(({ theme }) => ({
  '& .MuiPopover-paper': {
    padding: theme.spacing(2),
    minWidth: '200px',
    borderRadius: '8px',
    boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)'
  }
}));

const MenuItem = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1),
  cursor: 'pointer',
  borderRadius: '4px',
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  }
}));

function MyAppBar({ title, showSearch, showAdd, showTabs, tabIndex, setTabIndex, tabs }) {
  const navigate = useNavigate();

  //console.log("页面刷新");


  const handleTabClick = (index) => {
    setTabIndex(index);
  };
  //console.log("tabindex", tabIndex);

  const generateTabContent = () => {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center', marginRight: '16px' }}>
        {tabs.map((tab, index) => (
          <Typography
            key={index}
            variant="h6"
            component="div"
            sx={{
              flexGrow: 1,
              color: '#ffbb34',
              cursor: 'pointer',
              marginRight: index === tabs.length - 1 ? '0px' : '16px', // 最后一个 tab 不需要 marginRight
              fontWeight: tabIndex === index ? 'bold' : 'normal', // 设置字体粗细
              borderBottom: tabIndex === index ? '2px solid #ffbb34' : 'none', // 添加下划线
            }}
            onClick={() => handleTabClick(index)}
          >
            {tab}
          </Typography>
        ))}
      </Box>
    );
  };

  const handleHome = () => {
    navigate("/home");
  };

  const handleAddVideo = () => {
    window.open('/upload', '_blank'); // 在新窗口打开 /upload 页面
  };

  return (
    <Box>
      <AppBar
        position="static" sx={{
          backgroundColor: 'white',
          boxShadow: 'none', // 移除阴影 或 '0px 2px 4px -1px rgba(0,0,0,0.2)', // 设置更柔和的阴影
        }}>
        <Toolbar>

          {/* 点击图标跳转到HomePage */}
          <img
            src={`${GlobalData.serverURL}/icon/toptalk_logo_nobg.svg`}
            alt="TopTalk Logo"
            width="40"
            height="40"
            style={{ cursor: 'pointer', marginRight: '16px' }} // 添加点击样式和右边距
            onClick={handleHome} // 添加点击事件处理函数
          />
          {showTabs && generateTabContent()} {/* 如果需要渲染 tabs，则渲染 */}

          <Typography variant="h6" component="div" sx={{ flexGrow: 1, color: '#ffbb34' }}>
            {title}
          </Typography>

          {/* "搜索"图标 */}
          {showSearch && (
            <IconButton
              color="inherit"
              aria-label="search"
              onClick={() => navigate('/search')}
            >
              <SearchIcon style={{ color: '#ffbb34' }} />
            </IconButton>
          )}
          {/* "上传"图标 */}
          {showAdd && (
            <IconButton
              color="inherit"
              aria-label="add"
              onClick={handleAddVideo}
            >
              <AddIcon style={{ color: '#ffbb34' }} />
            </IconButton>
          )}

          {/* "我的"图标 */}
          <UserProfileIcon />
        </Toolbar>
      </AppBar>
      {/* 添加一条浅灰色的分割线*/}
      <Box sx={{ height: '1px', backgroundColor: '#eeeeee' }} />
    </Box>
  );
}

// 新建的组件 UserProfileIcon
function UserProfileIcon() {
  const [phone, setPhone] = useState('');
  const [totalPoints, setTotalPoints] = useState(0);
  const navigate = useNavigate();

  // 添加 popoverOpen 状态
  const [popoverOpen, setPopoverOpen] = useState(false);

  // 修改 useEffect,添加 popoverOpen 依赖
  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        const unique_id = localStorage.getItem('unique_id');
        const [userResponse, pointsResponse] = await Promise.all([
          axios.get(`${GlobalData.serverURL}/get_user_info/?unique_id=${unique_id}`),
          axios.get(`${GlobalData.serverURL}/get_total_points/`)
        ]);

        if (userResponse.status === 200) {
          setPhone(userResponse.data.phone_number);
        }
        if (pointsResponse.status === 200) {
          setTotalPoints(pointsResponse.data.total_points);
        }
      } catch (error) {
        console.error('获取用户信息失败:', error);
      }
    };

    // 当 popover 打开时获取最新积分
    if (popoverOpen) {
      fetchUserInfo();
    }
  }, [popoverOpen]); // 添加 popoverOpen 作为依赖

  const handleLogout = () => {
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('unique_id');
    navigate('/login');
  };

  const handleStudyHistory = () => {
    window.open('/study-history', '_blank');
  };

  const handlePointsDetail = () => {
    window.open('/points-detail', '_blank');
  };

  // Popover 的内容
  const content = (
    <Box sx={{ width: 200 }}>
      <Box sx={{ mb: 2 }}>
        <Typography variant="subtitle1" sx={{ fontWeight: 'bold', color: '#333' }}>
          {phone}
        </Typography>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 1,
            cursor: 'pointer',
            '&:hover': { color: '#ffbb34' }
          }}
          onClick={handlePointsDetail}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <StarIcon sx={{ color: '#ffbb34', fontSize: 16 }} />
            <Typography variant="body2">{totalPoints}</Typography>
          </Box>
          <Typography variant="body2" color="primary">查看明细 </Typography>
        </Box>
      </Box>

      <Divider style={{ margin: '8px 0' }} />

      <Box
        sx={{
          p: 1,
          cursor: 'pointer',
          display: 'flex',
          alignItems: 'center',
          gap: 1,
          '&:hover': { backgroundColor: '#f5f5f5' },
          borderRadius: 1
        }}
        onClick={handleStudyHistory}
      >
        <HistoryIcon sx={{ color: '#666' }} />
        <Typography variant="body2">学习记录</Typography>
      </Box>

      <Box
        sx={{
          p: 1,
          cursor: 'pointer',
          display: 'flex',
          alignItems: 'center',
          gap: 1,
          '&:hover': { backgroundColor: '#f5f5f5' },
          borderRadius: 1,
          color: 'error.main'
        }}
        onClick={handleLogout}
      >
        <LogoutIcon sx={{ color: 'inherit' }} />
        <Typography variant="body2">退出登录</Typography>
      </Box>
    </Box>
  );

  return (
    <Popover
      content={content}
      trigger="hover"
      placement="bottomRight"
      overlayStyle={{ padding: 0 }}
      overlayInnerStyle={{ padding: 16, borderRadius: 8 }}
      onOpenChange={(visible) => setPopoverOpen(visible)}
    >
      <IconButton
        size="large"
        edge="end"
        aria-label="account of current user"
        color="inherit"
      >
        <AccountCircleOutlined style={{ color: '#ffbb34' }} />
      </IconButton>
    </Popover>
  );
}

export default MyAppBar;