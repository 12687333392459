// BatchUpload.js  新增文件

import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import {
  Box,
  Grid2,
  LinearProgress,
  Typography,
  Card,
  CardContent,
} from '@mui/material';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import { GlobalData } from '../global_data';




function BatchUpload() {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadingFiles, setUploadingFiles] = useState({});
  const unique_id = localStorage.getItem('unique_id');
  const uploadQueueRef = useRef([]);

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    setSelectedFiles(files);

    // 初始化所有文件的上传状态
    const initialUploadingFiles = files.reduce((acc, file) => ({
      ...acc,
      [file.name]: { progress: 0, status: '等待中', speed: 0 },
    }), {});

    setUploadingFiles(initialUploadingFiles);
    uploadQueueRef.current = [...files];

    // 如果当前没有在上传，开始上传队列
    if (!isUploading) {
      setIsUploading(true);
      uploadNext();
    }
  };

  const uploadVideo = async (file) => {
    const formData = new FormData();
    formData.append('videos', file);
    formData.append('unique_id', unique_id);

    let lastLoaded = 0;
    let lastTime = Date.now();

    try {
      const response = await axios.post(
        `${GlobalData.serverURL}/upload/`,
        formData,
        {
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );

            const now = Date.now();
            const timeDiff = now - lastTime;

            let speed = 0;
            if (timeDiff > 1000) { // 每秒更新一次速度
              const bytesDiff = progressEvent.loaded - lastLoaded;
              speed = (bytesDiff / 1024) / (timeDiff / 1000); // 转换为 KB/s
              lastLoaded = progressEvent.loaded;
              lastTime = now;
            }

            setUploadingFiles(prev => ({
              ...prev,
              [file.name]: { progress: percentCompleted, status: '上传中', speed: Math.round(speed) },
            }));
          },
        }
      );

      if (response.status === 200) {
        setUploadingFiles(prev => ({
          ...prev,
          [file.name]: { progress: 100, status: '上传成功', speed: 0 },
        }));
      }
    } catch (error) {
      const errorMessage = error.response?.data?.detail || error.message;
      setUploadingFiles(prev => ({
        ...prev,
        [file.name]: { progress: 0, status: `上传失败: ${errorMessage}`, speed: 0 },
      }));
    }
  };

  const uploadNext = async () => {
    if (uploadQueueRef.current.length === 0) {
      setIsUploading(false);
      return;
    }

    const nextFile = uploadQueueRef.current[0];
    uploadQueueRef.current = uploadQueueRef.current.slice(1);

    await uploadVideo(nextFile);
    uploadNext(); // 继续上传下一个文件
  };


  return (
    <Box style={{ paddingTop: '12px', display: 'flex', justifyContent: 'center', width: '100%' }} >
      <Grid2 container spacing={2} width={"80%"}>
        <Grid2 item={"true"} size={12} >
          {/* 文件选择 */}
          <Card style={{
            padding: '48px',
            backgroundColor: '#2E32380D',
            border: 'none', // 确保没有边框
            boxShadow: 'none', // 确保没有阴影
          }}>
            {selectedFiles.length === 0 && (
              <Box
                sx={{
                  height: 200,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  border: '1px dashed #ccc',
                  borderRadius: 1,
                  cursor: 'pointer',
                  '&:hover': {
                    backgroundColor: 'white',
                  },
                }}
                onClick={() => { document.getElementById('videoInput').click() }}
              >
                {/* 上传图标 */}
                <CloudUploadOutlinedIcon style={{ fontSize: 48, color: "grey" }} />
                <Typography variant="body1" color="textSecondary">
                  点击上传
                </Typography>
                {/* 限制说明 */}
                <Box sx={{ mt: 2 }}>
                  <Typography variant="caption" color="textSecondary">
                    支持常用视频格式，推荐 mp4、webm。
                  </Typography>
                  <Box /> {/* 添加间隙 */}
                  <Typography variant="caption" color="textSecondary">
                    视频文件大小不超过 200M，时长在 10 分钟以内。
                  </Typography>
                  <Box /> {/* 添加间隙 */}
                  <Typography variant="caption" color="textSecondary">
                    分辨率范围：720P - 1080P。
                  </Typography>
                </Box>

              </Box>

            )}
            <CardContent>
              <input
                type="file"
                accept="video/*"
                onChange={handleFileChange}
                id="videoInput"
                style={{ display: 'none' }}
                multiple // 添加 multiple 属性
              />
            </CardContent>


          </Card>
        </Grid2>
        {/* 显示上传进度 */}
        {Object.keys(uploadingFiles).map((fileName) => ( // 使用 Object.keys 来遍历 uploadingFiles
          <Grid2 item xs={12} sm={6} md={4} key={fileName}>
            <Card>
              <CardContent>
                <Typography variant="body2">{fileName}</Typography>
                <LinearProgress
                  variant="determinate"
                  value={uploadingFiles[fileName].progress} // 直接使用 fileName 访问进度
                  sx={{ height: 8, borderRadius: 4 }}
                />
                <Typography variant="body2" sx={{ textAlign: 'right' }}>
                  {uploadingFiles[fileName].progress}% ({uploadingFiles[fileName].speed} KB/s) {uploadingFiles[fileName].status}
                </Typography>
              </CardContent>
            </Card>
          </Grid2>
        ))}
      </Grid2>
    </Box>

  );
}

export default BatchUpload;