import React, { useEffect, useRef, useCallback } from 'react';
import { GlobalData } from '../global_data';
import axios from 'axios';  // 使用配置好的 axios 实例

const StudyRecordManager = ({
  videoFilename,
  isPlaying,
  isWindowActive,
  isFullscreen,
  onQuestionAnswered,
  onPointsEarned
}) => {
  const watchTimeRef = useRef(0);
  const pointsTimeRef = useRef(0);
  const lastUpdateRef = useRef(0);
  const updateIntervalRef = useRef(null);
  const isUpdatingRef = useRef(false);
  const isAnimatingRef = useRef(false);
  const showAnimation = false; // 添加动画开关，默认关闭

  useEffect(() => {
    const shouldCount = isPlaying && (isWindowActive || isFullscreen);
    console.log('StudyRecordManager 状态更新:', {
      isPlaying,
      isWindowActive,
      isFullscreen,
      shouldCount,
      currentWatchTime: watchTimeRef.current
    });

    if (shouldCount) {
      console.log('开始计时');
      // 每秒更新一次计时器
      updateIntervalRef.current = setInterval(() => {
        watchTimeRef.current += 1; // 每秒增加1秒
        pointsTimeRef.current += 1;
        console.log('当前观看时长:', watchTimeRef.current);

        // 每10秒更新一次记录
        const now = Date.now();
        if (now - lastUpdateRef.current >= 10000) {
          console.log('触发10秒更新');
          updateStudyRecord();
          if (pointsTimeRef.current >= 60) {
            const points = Math.floor(pointsTimeRef.current / 60) * 10;
            addPoints(points, pointsTimeRef.current);
            pointsTimeRef.current = pointsTimeRef.current % 60;
          }
          lastUpdateRef.current = now;
        }
      }, 1000);
    } else {
      console.log('停止计时');
      if (updateIntervalRef.current) {
        clearInterval(updateIntervalRef.current);
        if (watchTimeRef.current > 0) {
          console.log('停止时更新记录');
          updateStudyRecord();
          if (pointsTimeRef.current >= 60) {
            const points = Math.floor(pointsTimeRef.current / 60) * 10;
            addPoints(points, pointsTimeRef.current);
            pointsTimeRef.current = 0;
          }
        }
      }
    }

    return () => {
      if (updateIntervalRef.current) {
        clearInterval(updateIntervalRef.current);
      }
    };
  }, [isPlaying, isWindowActive, isFullscreen, videoFilename]);

  //观看视频获得积分
  const addPoints = async (points, seconds) => {
    if (points <= 0 || isAnimatingRef.current) return;

    try {
      await axios.post(`${GlobalData.serverURL}/add_points/`, {
        points: points,
        type: 1,
        description: `观看视频 ${seconds} 秒`
      });
      console.log(`添加积分成功: ${points} 点 (观看 ${seconds} 秒)`);

      if (onPointsEarned && !isAnimatingRef.current && showAnimation) {
        console.log('触发积分动画回调');
        isAnimatingRef.current = true;
        onPointsEarned();
        setTimeout(() => {
          isAnimatingRef.current = false;
        }, 2000);
      }
    } catch (error) {
      console.error('添加积分失败:', error);
      isAnimatingRef.current = false;
    }
  };

  //更新学习记录
  const updateStudyRecord = async () => {
    if (watchTimeRef.current === 0 || isUpdatingRef.current) {
      console.log('跳过更新：时长为0或正在更新中');
      return;
    }

    try {
      isUpdatingRef.current = true;
      console.log('准备更新学习记录，观看时长:', watchTimeRef.current);

      // 更新学习记录
      await axios.post(`${GlobalData.serverURL}/study_record/`, {
        video_filename: videoFilename,
        watch_duration: watchTimeRef.current
      });

      watchTimeRef.current = 0;
    } catch (error) {
      console.error('更新记录失败:', error);
    } finally {
      isUpdatingRef.current = false;
    }
  };

  //回答问题获得积分
  const handleQuestionAnswered = useCallback(async (questionData) => {
    if (isAnimatingRef.current) return;

    console.log('收到问答记录数据:', questionData);
    try {
      await axios.post(`${GlobalData.serverURL}/study_record/`, {
        video_filename: videoFilename,
        qa_record: questionData
      });

      const points = questionData.contentAccuracy + questionData.grammarAccuracy;
      await axios.post(`${GlobalData.serverURL}/add_points/`, {
        points: points,
        type: 2,
        description: `回答问题：${questionData.question}`
      });

      if (onPointsEarned && !isAnimatingRef.current && showAnimation) {
        console.log('问答获得积分，触发积分动画回调');
        isAnimatingRef.current = true;
        onPointsEarned();
        setTimeout(() => {
          isAnimatingRef.current = false;
        }, 2000);
      }
    } catch (error) {
      console.error('记录问答失败:', error);
      isAnimatingRef.current = false;
    }
  }, [videoFilename, onPointsEarned]);

  // 将handleQuestionAnswered传递给父组件
  useEffect(() => {
    if (onQuestionAnswered && typeof onQuestionAnswered === 'function') {
      console.log('设置 onQuestionAnswered 回调函数');
      onQuestionAnswered(handleQuestionAnswered);
    }
  }, [onQuestionAnswered, handleQuestionAnswered]);

  return null;
};

export default StudyRecordManager; 