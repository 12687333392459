import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { GlobalData } from '../global_data';
import Grid2 from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import { useLocation, useNavigate } from 'react-router-dom';
import VideoCard from './VideoCard';
import MyAppBar from '../components/MyAppBar';
import { throttle } from 'lodash';



//待解决问题：在strict mode下，page=1会被加载2次，导致前10个视频会被加载2次
function HomePage() {
  const [videos, setVideos] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(1); // 0 for "我的视频", 1 for "其他视频"
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMore, setHasMore] = useState(true); // 添加 hasMore 状态，控制是否继续加载视频
  const navigate = useNavigate();
  const unique_id = localStorage.getItem('unique_id');
  const prevSelectedIndex = useRef(); // 使用 useRef 存储上一次的 selectedIndex
  const isLoadingRef = useRef(false); // 添加用于跟踪实时加载状态的 ref
  const [isLoading, setIsLoading] = useState(false); // 保留 isLoading state，仅用于 UI 渲染

  const location = useLocation(); // 获取当前页面的路由信息

  //console.log('unique_id', unique_id);

  // 添加一个检查页面是否需要加载更多的函数
  const checkNeedMore = () => {
    const windowHeight = window.innerHeight;
    const documentHeight = document.documentElement.scrollHeight;

    // 如果文档高度小于或接近窗口高度，说明页面未填满
    return documentHeight <= windowHeight + 300;
  };

  useEffect(() => {
    const fetchInitialVideos = async () => {
      if (selectedIndex === prevSelectedIndex.current) return;

      console.log("selectedIndex changed, resetting parameters...");
      prevSelectedIndex.current = selectedIndex;

      // 重置状态
      setVideos([]);
      setHasMore(true);
      setCurrentPage(1);

      // 确保之前的加载已经完成
      if (isLoadingRef.current) {
        console.log('Previous loading not finished, waiting...');
        return;
      }

      isLoadingRef.current = true;
      setIsLoading(true);

      try {
        const url = selectedIndex === 0
          ? `${GlobalData.serverURL}/get_video_list/?page=1&unique_id=${unique_id}`
          : `${GlobalData.serverURL}/get_video_list/?page=1&&exclude_unique_id=${unique_id}`;

        console.log('fetching videos from:', url);
        const response = await axios.get(url);

        if (response.status === 200) {
          const newVideos = response.data.videos;
          setVideos(newVideos);
          setHasMore(newVideos.length > 0);

          // 立即检查是否需要加载更多，而不是使用 setTimeout
          if (newVideos.length > 0 && checkNeedMore()) {
            console.log('Screen not filled after initial load, will load more...');
            // 使用 Promise.resolve().then 确保状态更新后再设置 currentPage
            Promise.resolve().then(() => {
              isLoadingRef.current = false;
              setCurrentPage(2);
            });
            return; // 提前返回，避免设置 isLoadingRef.current = false
          }
        }
      } catch (error) {
        console.error('Error fetching videos:', error);
      } finally {
        if (isLoadingRef.current) { // 只有在没有触发加载更多时才重置加载状态
          isLoadingRef.current = false;
          setIsLoading(false);
        }
      }
    };

    fetchInitialVideos();
  }, [selectedIndex]);

  useEffect(() => {
    const fetchMoreVideos = async () => {
      // 如果是初始加载或标签切换，不需要加载
      if (currentPage === 1) {
        console.log('Skip loading more: initial page');
        return;
      }

      if (!hasMore || isLoadingRef.current) {
        console.log('Skip loading more videos:');
        console.log('- currentPage:', currentPage);
        console.log('- hasMore:', hasMore);
        console.log('- isLoadingRef.current:', isLoadingRef.current);
        console.log('- selectedIndex:', selectedIndex);
        return;
      }

      isLoadingRef.current = true;
      setIsLoading(true);

      try {
        const url = selectedIndex === 0
          ? `${GlobalData.serverURL}/get_video_list/?page=${currentPage}&unique_id=${unique_id}`
          : `${GlobalData.serverURL}/get_video_list/?page=${currentPage}&&exclude_unique_id=${unique_id}`;

        console.log('fetching more videos from:', url);
        const response = await axios.get(url);

        if (response.status === 200) {
          const newVideos = response.data.videos;
          setVideos(prevVideos => [...prevVideos, ...newVideos]);
          setHasMore(newVideos.length > 0);

          // 检查是否需要继续加载
          if (newVideos.length > 0 && checkNeedMore()) {
            console.log('Screen still not filled, will load more...');
            // 使用 Promise.resolve().then 确保状态更新后再设置 currentPage
            Promise.resolve().then(() => {
              isLoadingRef.current = false;
              setCurrentPage(prev => prev + 1);
            });
            return; // 提前返回，避免设置 isLoadingRef.current = false
          }
        }
      } catch (error) {
        console.error('Error fetching more videos:', error);
      } finally {
        if (isLoadingRef.current) { // 只有在没有触发加载更多时才重置加载状态
          isLoadingRef.current = false;
          setIsLoading(false);
        }
      }
    };

    fetchMoreVideos();
  }, [currentPage]);

  // 滚动到底部加载更多视频
  const handleScroll = throttle(() => {
    if (isLoadingRef.current || !hasMore) return;

    const scrollTop = window.scrollY || document.documentElement.scrollTop;
    const windowHeight = window.innerHeight;
    const documentHeight = document.documentElement.scrollHeight;

    if (windowHeight + scrollTop >= documentHeight - 100) {
      setCurrentPage(prev => prev + 1);
    }
  }, 500); // 500ms 的节流时间


  // 处理 tab 切换
  const handleTabChange = (event, newValue) => {
    setSelectedIndex(newValue);
    setCurrentPage(1); // 切换 tab 时重置页码
  };

  // 添加滚动事件监听器
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      // 移除滚动事件监听器
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // 在 HomePage 组件的开头添加样式常量
  const dividerTextStyle = {
    my: 2,
    color: 'text.secondary',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&::before, &::after': {
      content: '""',
      borderTop: '1px solid',
      borderColor: 'divider',
      width: '80px',
      mx: 2
    }
  };

  return (
    <div>
      {/* 使用 MyAppBar 组件 */}
      <MyAppBar
        title=""
        showSearch
        showAdd
        showTabs // 显示 tabs
        tabs={['我的视频', '其他视频']} // 设置 tab 内容
        tabIndex={selectedIndex} // 当前选中 tab 的索引
        setTabIndex={setSelectedIndex} // 更新 tab 索引的函数
      />

      <Grid2 container spacing={2} sx={{ padding: '16px' }}>
        {/* 修改处：添加 Link 组件 */}
        {!isLoading && selectedIndex === 0 && videos.length === 0 && (
          <Grid2 item xs={12}>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 4 }}>
              <Typography variant="body1" align="center">
                你还没有自己的视频哦，
                <Link
                  component="button"
                  variant="body1"
                  onClick={() => navigate('/upload')}
                  sx={{  // 添加 sx 属性来自定义样式
                    fontSize: 'inherit',  // 设置字体大小与周围文本相同
                    lineHeight: 'inherit', // 设置行高与周围文本相同
                    verticalAlign: 'baseline',  // 设置垂直对齐方式
                    padding: 0, margin: 0, // 去除默认 padding 和 margin
                    '&.MuiTypography-root': {
                      color: 'blue' // 设置链接的颜色
                    },

                  }}
                >
                  上传
                </Link>
                或者
                <Link
                  component="button"
                  variant="body1"
                  onClick={() => handleTabChange(null, 1)}
                  sx={{  // 添加 sx 属性来自定义样式
                    fontSize: 'inherit', // 设置字体大小与周围文本相同
                    lineHeight: 'inherit', // 设置行高与周围文本相同
                    verticalAlign: 'baseline', // 设置垂直对齐方式
                    padding: 0, margin: 0, // 去除默认 padding 和 margin
                    '&.MuiTypography-root': {
                      color: 'blue' // 设置链接的颜色
                    },
                  }}
                >
                  看看别人的视频
                </Link>
              </Typography>
            </Box>
          </Grid2>
        )}
        {videos.map((video) => (
          <Grid2 item xs={12} sm={6} md={4} lg={3} xl={2} key={video.video_id}          >
            {/* 设置响应式布局 */}
            <VideoCard
              title={video.title} // 直接传递 title，不需要再判断
              stored_filename={video.stored_filename}
              isTranscode={video.is_transcode} // 传递 isTranscode 属性
              originalFilename={video.original_filename} // 传递 originalFilename 属性
              thumbnailUrl={`${GlobalData.serverURL}/thumbnails/${video.stored_filename.replace(
                '.mp4',
                ''
              )}.jpg`}
              pageType={selectedIndex === 0 ? 'mine' : 'others'} // 传递页面类型
            />
          </Grid2>
        ))}
        {/* 加载更多指示器 */}
        {isLoading && (
          <Grid2 item xs={12}>
            <Typography variant="body2" align="center" sx={dividerTextStyle}>
              加载中...
            </Typography>
          </Grid2>
        )}

        {/* 到底啦提示 */}
        {!isLoading && !hasMore && videos.length > 0 && (
          <Grid2 item xs={12}>
            <Typography variant="body2" align="center" sx={dividerTextStyle}>
              到底啦
            </Typography>
          </Grid2>
        )}
      </Grid2>

    </div>


  );
}

export default HomePage; 