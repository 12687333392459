import React, { useEffect, useState, useCallback, useRef } from 'react';
import { Box, Typography } from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import { keyframes } from '@mui/system';

const fadeUpAnimation = keyframes`
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  20% {
    opacity: 1;
    transform: translateY(0);
  }
  80% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(-20px);
  }
`;

function PointsAnimation({ points, onAnimationEnd }) {
  const [show, setShow] = useState(true);
  const timerRef = useRef(null);

  const handleAnimationEnd = useCallback(() => {
    console.log('PointsAnimation: 动画结束,准备隐藏');
    setShow(false);
    if (onAnimationEnd) {
      console.log('PointsAnimation: 调用 onAnimationEnd 回调');
      onAnimationEnd();
    }
  }, [onAnimationEnd]);

  useEffect(() => {
    console.log('PointsAnimation: 设置定时器');
    timerRef.current = setTimeout(handleAnimationEnd, 2000);

    return () => {
      console.log('PointsAnimation: 清理定时器');
      if (timerRef.current) {
        clearTimeout(timerRef.current);
        timerRef.current = null;
      }
    };
  }, []);

  if (!show) {
    console.log('PointsAnimation: show 为 false,不显示动画');
    return null;
  }

  return (
    <Box
      sx={{
        position: 'fixed',
        top: 74,
        right: 20,
        display: 'flex',
        alignItems: 'center',
        gap: 0.5,
        padding: '8px 16px',
        backgroundColor: 'rgba(255, 187, 52, 0.9)',
        borderRadius: '20px',
        boxShadow: '0 2px 8px rgba(0,0,0,0.2)',
        animation: `${fadeUpAnimation} 2s ease-out forwards`,
        zIndex: 2000,
      }}
      onAnimationEnd={() => {
        console.log('PointsAnimation: CSS动画结束');
        handleAnimationEnd();
      }}
    >
      <StarIcon sx={{ color: 'white', fontSize: 24 }} />
      <Typography
        variant="body1"
        sx={{
          color: 'white',
          fontWeight: 'bold',
          fontSize: '16px',
        }}
      >
        +{points}
      </Typography>
    </Box>
  );
}

export default React.memo(PointsAnimation, (prevProps, nextProps) => {
  return prevProps.points === nextProps.points;
}); 