import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import LoginPage from './components/LoginPage';
import HomePage from './components/HomePage';
import PlayPage from './components/PlayPage';
import SearchPage from './components/SearchPage';
import UploadPage from './components/UploadPage';
import EditLessonPlanPage from './components/EditLessonPlanPage';
import BatchUpload from './components/BatchUpload';
import './components/axiosInstance';
import 'video-react/dist/video-react.css';

import { MantineProvider } from '@mantine/core';
import StudyHistory from './components/StudyHistory';
import PointsDetailPage from './components/PointsDetailPage';
import PlayVideo from './components/PlayVideo';
import PlayVideo2 from './components/PlayVideo2';

function App() {
  return (
    <BrowserRouter>
      <MantineProvider withGlobalStyles withNormalizeCSS>
        <Routes>
          <Route path="/" element={<Navigate to="/home" />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/home" element={<HomePage />} />
          <Route path="/play/:stored_filename" element={<PlayPage />} />
          <Route path="/search" element={<SearchPage />} />
          <Route path="/upload" element={<UploadPage />} />
          <Route path="/edit/:video_filename" element={<EditLessonPlanPage />} />
          <Route path="/batchupload" element={<BatchUpload />} />
          <Route path="/study-history" element={<StudyHistory />} />
          <Route path="/points-detail" element={<PointsDetailPage />} />
          <Route path="/playvideo/:videoFilename" element={<PlayVideo />} />
          <Route path="/playvideo2/:videoFilename" element={<PlayVideo2 />} />
        </Routes>
      </MantineProvider>
    </BrowserRouter>
  );
}

export default App;